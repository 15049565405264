import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localStorageUtils from 'src/utils/localStorageUtils';
import { BASE_URL } from '../../utils/appConstants';

export const caseApi = createApi({
  reducerPath: 'cases',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('token', localStorageUtils.getToken());
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getCasesByNavigator: builder.query({
      query: ({ navigatorId, status }) => {
        console.log('getCasesByNavigator................');
        return {
          method: 'GET',
          url: `/case/navigator/${navigatorId}/list`,
          params: { status }
        };
      }
    }),
    getCasesByUserAndNavigator: builder.query({
      query: ({ userId, navigatorId, status }) => ({
        method: 'GET',
        url: `/case/navigator/${navigatorId}/patient/${userId}/list`,
        params: { status }
      })
    }),
    getCasesByUser: builder.query(),
    createOrUpdateCase: builder.mutation({
      query: (nvCase) => {
        return {
          method: 'PUT',
          url: '/case',
          body: nvCase
        };
      }
    }),
    removeCase: builder.mutation()
  })
});

export const {
  useGetCasesByNavigatorQuery,
  useGetCasesByUserAndNavigatorQuery,
  useCreateOrUpdateCaseMutation
} = caseApi;
