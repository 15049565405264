import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateNavigator } from 'src/api';

const AccountProfileDetails = (props) => {
  //console.log("props.profile", props.profile);
  const { profile, setReloadProfile } = props;
  const [values, setValues] = useState();

  const [saveMessageDisplay, setSaveMessageDisplay] = useState('none');
  const [saveMessage, setSaveMessage] = useState();
  const [saveMessageCode, setSaveMessageCode] = useState();
  //saveButtonDisabled
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    // console.log('load profile to values ...', profile);
    if (profile) {
      delete profile.avatar;
      delete profile.password;
      setValues({
        ...profile
      });
    }
  }, [profile]);

  const handleUpdateProfile = async () => {
    // console.log('To handleUpdateProfile: ', values);
    setSaveMessageDisplay('none');
    setSaveButtonDisabled(true);
    const saveResult = await updateNavigator(values);
    // console.log(saveResult);
    setSaveMessageDisplay('');
    setSaveButtonDisabled(false);
    if (saveResult.code === 1) {
      setSaveMessageCode('success');
      setSaveMessage(saveResult.data);
      setReloadProfile((pre) => pre + 1);
    } else {
      setSaveMessageCode('error');
      setSaveMessage(saveResult.msg);
    }
  };

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="First Name"
                name="firstName"
                onChange={handleChange}
                value={values ? values.firstName : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                value={values ? values.lastName : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Preferred Name"
                name="preferredName"
                onChange={handleChange}
                value={values ? values.preferredName : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-gender-native-simple">
                  Gender
                </InputLabel>
                <Select
                  native
                  value={values ? values.gender : ''}
                  onChange={handleChange}
                  label="Gender"
                  inputProps={{
                    name: 'gender',
                    id: 'outlined-gender-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'Male'}>Male</option>
                  <option value={'Female'}>Female</option>
                  <option value={'Others'}>Others</option>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={values ? values.email : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                onChange={handleChange}
                value={values ? values.phone : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Institution"
                name="institution"
                onChange={handleChange}
                value={values ? values.institution : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Department"
                name="department"
                onChange={handleChange}
                value={values ? values.department : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                onChange={handleChange}
                value={values ? values.title : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                value={values ? values.city : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              {/* <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values ? values.state : ''}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                label="State"
                name="state"
                onChange={handleChange}
                value={values ? values.state : ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                value={values ? values.country : ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Alert
            severity={saveMessageCode}
            sx={{ display: saveMessageDisplay }}
          >
            {saveMessage}
          </Alert>
          <Button
            color="primary"
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={handleUpdateProfile}
          >
            Save Profile
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
