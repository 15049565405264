import { Grid, Slider, Tooltip } from '@material-ui/core';
import BarriersCompare from '../barrier/BarriersCompare';
import moment from 'moment';
import { useState } from 'react';

function CaseSliderView({
  barrierList,
  encounterList,
  sliderStart,
  sliderEnd
}) {
  const date01 = moment(sliderStart);
  const date02 = moment(sliderEnd);
  const sliderMax = date02.diff(date01, 'days');
  const [currentValue, setCurrentValue] = useState(sliderMax);

  const marks = [
    ...encounterList?.map((encounter) => ({
      value: moment(encounter.startTime).diff(date01, 'days'),
      label: (
        <Tooltip
          key={encounter.id}
          disableFocusListener
          title={`Encounter: ${moment(encounter.startTime).format(
            'YYYY-MM-DD'
          )}`}
        >
          <div>E</div>
        </Tooltip>
      )
    }))
  ];
  return (
    <>
      <Grid container spacing={3} px={3}>
        <Grid item xs={12}>
          <Slider
            value={currentValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={1}
            marks={marks}
            min={0}
            max={sliderMax}
            onChange={(e, v) => {
              if (v !== currentValue) {
                setCurrentValue(v);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} p={3}>
        <Grid item xs={12}>
          <BarriersCompare
            barrierList={barrierList}
            currentDate={date01.add(currentValue, 'days').format('YYYY-MM-DD')}
            snapshotEncounter={encounterList[0]}
            defaultExpanded={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CaseSliderView;
