import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import BarrierListToolbar from 'src/components/barrier/BarrierListToolbar';
import localStorageUtils from 'src/utils/localStorageUtils';
import BarrierCard from 'src/components/barrier/BarrierCard';
import { useGetBarriersByNavigatorQuery } from 'src/store/api/barrierApi';
import LoadingCircle from 'src/components/LoadingCirecle';

const BarrierList = () => {
  const currentUser = localStorageUtils.getCurrentUser();

  const { data, isFetching, error } = useGetBarriersByNavigatorQuery({
    navigatorId: currentUser.id,
    status: 'all'
  });

  const barrierList = data?.data;

  return (
    <>
      <Helmet>
        <title>Barriers | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <BarrierListToolbar />

          <Box sx={{ pt: 3 }}>
            {isFetching ? (
              <LoadingCircle />
            ) : error ? (
              <div>Error loading barriers</div>
            ) : (
              <Grid container spacing={5}>
                {barrierList?.map((barrier) => (
                  <Grid item key={barrier.id} lg={12} md={12} xs={12}>
                    <BarrierCard barrier={barrier} sx={{boxShadow: 12}} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BarrierList;
