import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useState } from 'react';
// import { IMGAE_REQUEST_PREFIX } from 'src/utils/appConstants';
// import { Link } from 'react-router-dom';
import { updateRunningDetails } from 'src/api';
import { Link } from 'react-router-dom';

const MyUpcomingEncounters = (props) => {
  const { encounterList, reloadEncounterList } = props;
  const [open, setOpen] = useState(false);
  const [runningToDelete, setRunningToDelete] = useState();

  // const handleClickOpenDeleteDialog = (running) => {
  //   setRunningToDelete({ ...running });
  //   setOpen(true);
  // };

  const handleCloseDeleteDialog = () => {
    setRunningToDelete({});
    setOpen(false);
  };

  const handleDeleteRunning = async () => {
    // real delete from db
    // const r = await deleteRunningDetails(runningToDelete.id);
    // fake delete: set 'deleted' to 1
    runningToDelete.deleted = 1;
    const r = await updateRunningDetails(runningToDelete);
    // console.log(r);
    if (r.code === 1) {
      reloadEncounterList(pre => pre + 1);
    }
    setOpen(false);
  }

  return (
    <Card {...props}>
      <CardHeader title="My Upcoming Encounters" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Start Time
                </TableCell>
                <TableCell>
                  End Time
                </TableCell>
                <TableCell>
                  Patient
                </TableCell>
                <TableCell>
                  Case
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Notes
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {encounterList?.map((encounter) => (
                <TableRow
                  hover
                  key={encounter.id}
                >
                  <TableCell>
                    {/* <Chip
                      color="primary"
                      label={running.runDate}
                      size="small"
                    /> */}
                    {encounter.startTime}
                  </TableCell>
                  <TableCell>
                    {encounter.endTime}
                  </TableCell>
                  <TableCell>

                    {encounter.user.preferredName}
                  </TableCell>
                  {/* <TableCell>
                    {encounter.kcal}
                  </TableCell> */}
                  <TableCell>
                    {/* {encounter.imgUrl ? <img alt="encounter" src={IMGAE_REQUEST_PREFIX + encounter.imgUrl} width="100px" height="auto" /> : ''} */}
                    {encounter.caseId}
                  </TableCell>
                  <TableCell>
                    {encounter.type}
                  </TableCell>
                  <TableCell>
                    {/* <Button variant="text" color="primary" onClick={() => { handleClickOpenDeleteDialog(encounter) }}>
                      Delete
                    </Button> */}
                    {encounter.notes}
                  </TableCell>
                  <TableCell>
                    <Link to={'/app/add-encounter?encounterid=' + encounter.id} >
                      {'Edit'}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        {/* <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          <Link to="/app/running-detail">Running Submit</Link>
        </Button> */}
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Do you want to delete this running?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {runningToDelete?.mileage} {runningToDelete?.mileageUnit}s
            <br />
            {runningToDelete?.duration} minutes
            <br />
            {runningToDelete?.runDate}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteRunning} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
};

export default MyUpcomingEncounters;
