import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { barrierApi } from './api/barrierApi';
import { caseApi } from './api/caseApi';
import { encounterApi } from './api/encounterApi';
import { userApi } from './api/userApi';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [caseApi.reducerPath]: caseApi.reducer,
    [encounterApi.reducerPath]: encounterApi.reducer,
    [barrierApi.reducerPath]: barrierApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(caseApi.middleware)
      .concat(encounterApi.middleware)
      .concat(barrierApi.middleware)
});

setupListeners(store.dispatch);
