import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  // Paper,
  Select
  // Tab,
  // Tabs,
  // Typography,
  // Pagination
} from '@material-ui/core';
// import EncounterListToolbar from 'src/components/encounter/EncounterListToolbar';
import localStorageUtils from 'src/utils/localStorageUtils';
import {
  requestEncounters,
  requestUserDetails,
  requestBarriers
} from 'src/api';
import { useEffect, useState } from 'react';
// import EncounterCard from 'src/components/encounter/EncounterCard';
import TotalRunDuration from 'src/components/dashboard/TotalCases';
import TotalKcal from 'src/components/dashboard/TotalEncoutners';
// import TotalProfit from 'src/components/dashboard/TotalProfit';
// import PatientDetails from 'src/components/patient/PatientDetails';
import { useSearchParams } from 'react-router-dom';
// import TabPanel from 'src/components/TabPanel';
import CaseTabs from 'src/components/case/CaseTabs';
import { CASE_TYPE } from 'src/utils/appConstants';
import TotalPatients from 'src/components/dashboard/TotalPatients';
import EncounterCalendar from 'src/components/encounter/EncounterCalendar';
import { useGetCasesByUserAndNavigatorQuery } from 'src/store/api/caseApi';

const ViewPatient = () => {
  const [searchParams] = useSearchParams();

  const currentUser = localStorageUtils.getCurrentUser();
  const patientid = searchParams.get('patientid');

  const { data: patientCasesData } = useGetCasesByUserAndNavigatorQuery({
    userId: patientid,
    navigatorId: currentUser.id,
    status: 'all'
  });
  const caseList = patientCasesData?.data;

  const [currentCaseId, setCurrentCaseId] = useState(-1);
  const [encounterList, setEncounterList] = useState([]);
  const [barrierList, setBarrierList] = useState([]);
  const [details, setDetails] = useState({ navigatorId: currentUser.id });

  useEffect(() => {
    const loadUserDetails = async (patientId) => {
      const result = await requestUserDetails(patientId);
      setDetails(result.data);
    };

    const loadCurrentPatientEncounters = async (patientId) => {
      if (currentUser.id) {
        const r = await requestEncounters(currentUser.id, 'all', patientId);
        setEncounterList(r.data);
      }
    };

    const loadBarriers = async () => {
      if (currentUser.id) {
        const r = await requestBarriers(currentUser.id, 'all');
        setBarrierList(r.data);
      }
    };

    if (patientid && patientid != null) {
      loadUserDetails(patientid);
      loadCurrentPatientEncounters(patientid);
      if (caseList && caseList.length > 0) {
        setCurrentCaseId(caseList[0].id);
      }
      loadBarriers();
    }
  }, [searchParams, currentUser.id, patientid, caseList]);

  return (
    <>
      <Helmet>
        <title>View Patient | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {/* <PatientDetails details={details} /> */}

              <Card>
                <CardHeader
                  title={`${details.preferredName || details.firstName} (${
                    details.diagnosis
                  })`}
                />
              </Card>
            </Grid>

            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalRunDuration />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalKcal />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalPatients />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {/* <PatientDetails details={details} /> */}

              <Card sx={{ boxShadow: 12 }}>
                {/* <CardHeader
                  title={`${details.preferredName || details.firstName} (${
                    details.diagnosis
                  })`}
                /> */}
                <CardContent>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-patient-status">
                      Choose Case
                    </InputLabel>
                    <Select
                      native
                      value={currentCaseId}
                      label="Choose Case"
                      onChange={(e) => {
                        setCurrentCaseId(e.target.value);
                      }}
                    >
                      <option value={-1}>Choose Case...</option>
                      {caseList?.map((caseItem) => (
                        <option key={caseItem.id} value={caseItem.id}>
                          {caseItem.startDate} ({CASE_TYPE[caseItem.type]})
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* <Grid item lg={12} md={12} xl={12} xs={12}>
              <EncounterListToolbar />
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={3}>
                  {encounterList.map((encounter) => (
                    <Grid item key={encounter.id} lg={4} md={6} xs={12}>
                      <EncounterCard encounter={encounter} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid> */}
            {currentCaseId > 0 && (
              <>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <CaseTabs
                    currentCaseId={currentCaseId}
                    barrierList={barrierList.filter(
                      (item) => item.caseId === currentCaseId
                    )}
                    caseInfo={
                      caseList.filter((item) => item.id === currentCaseId)[0]
                    }
                    encounterList={encounterList.filter(
                      (item) => item.caseId === currentCaseId
                    )}
                  />
                </Grid>
                {currentCaseId === 0 && (
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <EncounterCalendar
                      encounterList={encounterList.filter(
                        (item) => item.caseId === currentCaseId
                      )}
                      caseList={caseList.filter(
                        (item) => item.id === currentCaseId
                      )}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ViewPatient;
