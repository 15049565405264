import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateBarrierDetails } from 'src/api';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { CASE_TYPE } from 'src/utils/appConstants';
import { BARRIER_STATUS } from 'src/utils/appConstants';

const BarrierDetails = forwardRef(
  ({ inStepper = false, details, cases, ...props }, _ref) => {
    const [values, setValues] = useState();

    const [saveMessageDisplay, setSaveMessageDisplay] = useState('none');
    const [saveMessage, setSaveMessage] = useState();
    const [saveMessageCode, setSaveMessageCode] = useState();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useImperativeHandle(_ref, () => ({
      getBarrierValues: () => values
    }));

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleDateChange = (datePick, dateType) => {
      console.log(dateType, datePick);
      setValues({
        ...values,
        [dateType]: moment(datePick._d).format('yyyy-MM-DD')
      });
    };

    useEffect(() => {
      if (details) {
        setValues({
          ...details
        });
      }
    }, [details]);

    const handleUpdateDetails = async () => {
      setSaveMessageDisplay('none');
      setSaveButtonDisabled(true);
      const saveResult = await updateBarrierDetails(values);
      console.log('saveResult: ', saveResult);
      setSaveMessageDisplay('');
      setSaveButtonDisabled(false);
      if (saveResult.code === 1) {
        setSaveMessageCode('success');
        setSaveMessage(saveResult.data);
      } else {
        setSaveMessageCode('error');
        setSaveMessage(saveResult.msg);
      }
    };
    return (
      <form autoComplete="off" noValidate {...props}>
        {!inStepper && (
          <p>
            <strong>PATIENT INFORMATION</strong>
          </p>
        )}
        <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
          {/* <CardHeader title="Barrier Details" /> */}
          {/* <Divider /> */}
          <CardContent>
            <Grid container spacing={3}>
              {/* <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-userId-native-simple">Patient</InputLabel>
                <Select
                  native
                  value={values ? values.userId : ''}
                  onChange={handleChange}
                  label="Patient"
                  inputProps={{
                    name: 'userId',
                    id: 'outlined-userId-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {patients.map((patient) => (
                    <option value={patient.id}>{patient.preferredName ? patient.preferredName : patient.lastName + ' ' + patient.firstName}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
              {!inStepper && (
                <Grid item md={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-caseId-native-simple">
                      Case
                    </InputLabel>
                    <Select
                      native
                      value={values ? values.caseId : ''}
                      onChange={handleChange}
                      label="Case"
                      inputProps={{
                        name: 'caseId',
                        id: 'outlined-caseId-native-simple'
                      }}
                    >
                      <option aria-label="None" value="" />
                      {cases?.map((theCase) => (
                        <option value={theCase.id} key={theCase.id}>
                          {theCase.user.preferredName +
                            ' | ' +
                            CASE_TYPE[theCase.type] +
                            ' | ' +
                            theCase.startDate}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-category-native-simple">
                    Type
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.category : ''}
                    onChange={handleChange}
                    label="Category"
                    inputProps={{
                      name: 'category',
                      id: 'outlined-category-native-simple'
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={0}>Care Coordination</option>
                    <option value={1}>Logistical</option>
                    <option value={2}>Health Promotion</option>
                    <option value={3}>Work/School</option>
                    <option value={4}>Communication/Education</option>
                    <option value={5}>Insurance/Costs</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  InputProps={{ readOnly: true }}
                  clearable
                  fullWidth
                  label="Introduce Date"
                  name="introduceDate"
                  value={values ? values.introduceDate : new Date()}
                  onChange={(date) => handleDateChange(date, 'introduceDate')}
                  format="MM/DD/yyyy"
                  variant="outlined"
                />
              </Grid>

              {/* 
            <Grid
              item
              md={6}
              xs={12}
            >
              <KeyboardDatePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                label="Close Date"
                name="closeDate"
                value={values ? values.closeDate : new Date()}
                onChange={date => handleCloseDateChange(date)}
                format="MM/DD/yyyy"
                variant="outlined"
              />
            </Grid> */}
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={values ? values.name : ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  onChange={handleChange}
                  value={values ? values.notes : ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Action"
                  name="action"
                  onChange={handleChange}
                  value={values ? values.action : ''}
                  variant="outlined"
                />
              </Grid>
              {!inStepper && (
                <Grid item md={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-status-native-simple">
                      Status
                    </InputLabel>
                    <Select
                      native
                      value={values ? values.status : 2}
                      onChange={handleChange}
                      label="Status"
                      inputProps={{
                        name: 'status',
                        id: 'outlined-status-native-simple'
                      }}
                    >
                      <option value={2}>{BARRIER_STATUS[2]}</option>
                      <option value={1}>{BARRIER_STATUS[1]}</option>
                      <option value={0}>{BARRIER_STATUS[0]}</option>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {+values?.status < 2 && (
                <Grid item md={6} xs={12}>
                  <KeyboardDatePicker
                    InputProps={{ readOnly: true }}
                    clearable
                    fullWidth
                    label="Start Date"
                    name="startDate"
                    value={values ? values.startDate : new Date()}
                    onChange={(date) => handleDateChange(date, 'startDate')}
                    format="MM/DD/yyyy"
                    variant="outlined"
                  />
                </Grid>
              )}
              {+values?.status === 0 && (
                <Grid item md={6} xs={12}>
                  <KeyboardDatePicker
                    InputProps={{ readOnly: true }}
                    clearable
                    fullWidth
                    label="Complete Date"
                    name="addressDate"
                    value={values ? values.addressDate : new Date()}
                    onChange={(date) => handleDateChange(date, 'addressDate')}
                    format="MM/DD/yyyy"
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        {!inStepper && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Alert
              severity={saveMessageCode}
              sx={{ display: saveMessageDisplay }}
            >
              {saveMessage}
            </Alert>
            <Button
              color="primary"
              variant="contained"
              disabled={saveButtonDisabled}
              onClick={handleUpdateDetails}
            >
              Save Barrier
            </Button>
          </Box>
        )}
      </form>
    );
  }
);

export default BarrierDetails;
