import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localStorageUtils from 'src/utils/localStorageUtils';
import { BASE_URL } from '../../utils/appConstants';

export const encounterApi = createApi({
  reducerPath: 'encounters',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('token', localStorageUtils.getToken());
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getEncountersByNavigator: builder.query({
      query: ({ navigatorId, status }) => ({
        method: 'GET',
        url: `/encounter/navigator/${navigatorId}/list`,
        params: { status }
      })
    }),
    getEncountersByUserAndNavigator: builder.query({
      query: ({ userId, navigatorId, status }) => ({
        method: 'GET',
        url: `/encounter/navigator/${navigatorId}/patient/${userId}/list`,
        params: { status }
      })
    })
  })
});

export const {
  useGetEncountersByNavigatorQuery,
  useGetEncountersByUserAndNavigatorQuery
} = encounterApi;
