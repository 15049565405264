const CURRENT_USER = 'CURRENT_USER';
const TOKEN = 'TOKEN';
const localStorageUtils = {
  setCurrentUser(user, token) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user || {}));
    if (token) {
      localStorage.setItem(TOKEN, token);
    }
  },

  removeCurrentUser() {
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem('jwt_access_token');
  },

  getCurrentUser() {
    return JSON.parse(localStorage.getItem(CURRENT_USER) || '{}');
  },

  getToken() {
    return localStorage.getItem(TOKEN) || '';
  }
};

export default localStorageUtils;
