import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localStorageUtils from 'src/utils/localStorageUtils';
import { BASE_URL } from '../../utils/appConstants';

export const userApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('token', localStorageUtils.getToken());
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getUsersByNavigator: builder.query({
      query: ({ navigatorId, status }) => {
        return {
          method: 'GET',
          url: `/user/navigator/${navigatorId}/list`,
          params: { status }
        };
      }
    }),
    createOrUpdateUser: builder.mutation({
      query: (patient) => {
        return {
          method: 'PUT',
          url: '/user',
          body: patient
        };
      }
    })
  })
});

export const { useGetUsersByNavigatorQuery, useCreateOrUpdateUserMutation } =
  userApi;
