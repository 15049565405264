import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import localStorageUtils from 'src/utils/localStorageUtils';

const OAuth2RedirectHandler = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const userId = searchParams.get('userId');
  const profileImage = searchParams.get('profileImage');

  if (token) {
    localStorageUtils.setCurrentUser({ id: userId, profileImage }, token);
    return <Navigate replace to="/app/dashboard" />;
  } else {
    return <Navigate replace to="/app/dashboard" />;
  }
};

export default OAuth2RedirectHandler;
