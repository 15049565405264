import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import localStorageUtils from "src/utils/localStorageUtils";
import { Navigate } from "react-router";
import { requestCaseDetails, requestPatients } from "src/api";
import CaseDetails from "src/components/case/CaseDetails";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const AddCase = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const [details, setDetails] = useState({
    navigatorId: currentUser.id,
    startDate: moment(new Date()).format("yyyy-MM-DD"),
    status: 1,
  });
  const [patientList, setPatientList] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const loadCaseDetails = async (caseId) => {
      const r1 = await requestCaseDetails(caseId);
      setDetails(r1.data);
    };
    const loadActivePatients = async (navigatorId) => {
      const r2 = await requestPatients(navigatorId, "1");
      setPatientList(r2.data);
    };
    loadActivePatients(currentUser.id);
    const caseId = searchParams.get("caseid");
    if (caseId && caseId != null) {
      loadCaseDetails(caseId);
    }
  }, [searchParams, currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Case Details | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <CaseDetails details={details} patients={patientList} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddCase;
