import * as d3 from 'd3';

const drawMeshTree = (svg, data, width, height) => {
  // For ease of reference
  const links = data.links;
  const nodes = data.nodes;

  // Setup simulation (how nodes move around)
  const simulation = d3
    .forceSimulation(nodes)
    .force(
      'link',
      d3.forceLink(links).id((d) => d.id)
    )
    .force('charge', d3.forceManyBody().strength(-70))
    // Since this is a disjoint graph, using
    .force('x', d3.forceX())
    .force('y', d3.forceY());

  // Setup the drag function that controls the drag behavior
  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  let drag = d3
    .drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);

  // Create a container and line for each link
  svg.attr('viewBox', [-width / 2, -height / 2, width, height]);
  const link = svg
    .append('g')
    .attr('class', 'link')
    .selectAll('line')
    .data(links)
    .join('line')
    .attr('stroke', 'red')
    .attr('stroke-width', (d) => 1)
    .attr('stroke-opacity', (d) => 0.5)
    .attr('marker-end', 'url(#end)');

  // Create a container for each node
  const node = svg
    .append('g')
    .selectAll('.node')
    .data(nodes)
    .join('g')
    .attr('class', 'node')
    .call(drag); // Attach the drag behavior to each node

  // Add a circle to each node container
  node.append('circle').attr('r', 5).attr('fill', 'red');

  // Add a label to each node container
  node
    .append('text')
    .text(function (d) {
      return d.id;
    })
    .attr('x', 6)
    .attr('y', 3)
    .style('font-size', '6px')
    .append('title')
    .text((d) => d.id);

  // Update the network visualization based on the simulation
  simulation.on('tick', () => {
    // Move the links to their new position
    link
      .attr('x1', (d) => d.source.x)
      .attr('y1', (d) => d.source.y)
      .attr('x2', (d) => d.target.x)
      .attr('y2', (d) => d.target.y);

    // Move the nodes to their new position
    node.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
  });
};

const drawMeshNodes = (svgE, chartData, width, height, onClick) => {
  const { nodes, links } = chartData;
  const types = ['licensing', 'suit', 'resolved'];
  const colors = ['#6baed6', '#b6cf5f', '#cfa45f', '#6a4682', '#b0286e'];
  const linkArc = (d) =>
    `M${d.source.x},${d.source.y}A0,0 0 0,1 ${d.target.x},${d.target.y}`;

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      'link',
      d3.forceLink(links).id((d) => d.id)
    )
    .force('charge', d3.forceManyBody().strength(-300))
    .force('x', d3.forceX())
    .force('y', d3.forceY())
    .force(
      'collide',
      d3.forceCollide((d) => 65)
    );

  const svg = svgE.attr('viewBox', [-width / 2, -height / 2, width, height]);

  // Per-type markers, as they don't inherit styles.
  svg
    .append('defs')
    .selectAll('marker')
    .data(types)
    .join('marker')
    .attr('id', 'arrow0')
    .attr('viewBox', '0 -5 10 10')
    .attr('refX', 38)
    .attr('refY', 0)
    .attr('markerWidth', 6)
    .attr('markerHeight', 6)
    .attr('orient', 'auto')
    .append('path')
    .attr('fill', '#6baed6')
    .attr('d', 'M0,-5L10,0L0,5');

  const link = svg
    .append('g')
    .attr('fill', 'none')
    .attr('stroke-width', 1.5)
    .selectAll('path')
    .data(links)
    .join('path')
    .attr('stroke', '#6baed6')
    //marker-end="url(#triangle)"
    .attr('marker-end', 'url(#arrow0)');

  // Setup the drag function that controls the drag behavior
  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  let drag = d3
    .drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);

  const node = svg
    .append('g')
    .attr('fill', 'currentColor')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round')
    .selectAll('g')
    .data(nodes)
    .join('g')
    .call(drag);

  node
    .append('circle')
    .attr('stroke', 'white')
    .attr('stroke-width', 1.5)
    .attr('r', 25)
    .attr('fill', (d) => colors[d.group % 5])
    .on('click', (event, d) => onClick(d))
    .append('svg:title')
    .text((d) => d.id);

  node
    .append('text')
    .attr('x', 30 + 4)
    .attr('y', '0.31em')
    .text((d) => d.name)
    .clone(true)
    .lower()
    .attr('fill', 'none')
    .attr('stroke', 'white')
    .attr('stroke-width', 3);

  node.on('dblclick', (e, d) => console.log(nodes[d.index]));

  simulation.on('tick', () => {
    link.attr('d', linkArc);
    node.attr('transform', (d) => `translate(${d.x},${d.y})`);
  });

  // invalidation.then(() => simulation.stop());
};

export { drawMeshTree, drawMeshNodes };
