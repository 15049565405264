import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';
import localStorageUtils from 'src/utils/localStorageUtils';
import { CalendarToday, Close, Grain, LocalHospital } from '@material-ui/icons';
import { useState } from 'react';
import EncounterCalendar from './encounter/EncounterCalendar';
import MeshTree from './help/MeshTree';
import { IoMdApps } from 'react-icons/io';
import { SiProbot } from 'react-icons/si';
import NewCase from './quicklink/NewCase';
import SingleQuestion from './bot/SingleQuestion';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const currentUser = localStorageUtils.getCurrentUser();

  const [showQuickLinks, setShowQuickLinks] = useState(false);

  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [meshTreeOpen, setMeshTreeOpen] = useState(false);
  const [newCaseOpen, setNewCaseOpen] = useState(false);
  const [navBotOpen, setNavBotOpen] = useState(false);

  return (
    <>
      <AppBar elevation={0} {...rest}>
        <Toolbar>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }}>
            <h2> </h2>
          </Box>
          <Typography variant="h5">
            <IconButton color="inherit" onClick={() => setShowQuickLinks(true)}>
              <IoMdApps />
            </IconButton>
          </Typography>
          <Typography variant="h5">{currentUser.firstName}</Typography>
          {currentUser.profileImage && (
            <Avatar
              src={currentUser.profileImage}
              sx={{
                height: 35,
                width: 35,
                marginLeft: 1
              }}
            />
          )}

          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* Quick Link Dialog for Calendar */}
      <Dialog
        fullScreen
        open={calendarModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ flexDirection: 'row-reverse' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setCalendarModalOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper sx={{ p: 3 }}>
          <EncounterCalendar encounterList={[]} caseList={[]} />
        </Paper>
      </Dialog>
      {/* Quick Link Dialog for create case */}
      <NewCase setNewCaseOpen={setNewCaseOpen} newCaseOpen={newCaseOpen} />
      {/* Quick Links for Mesh tree */}
      <Dialog
        fullScreen
        open={meshTreeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ flexDirection: 'row-reverse' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setMeshTreeOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MeshTree />
      </Dialog>
      {/* Quick Links for Navigation Bot */}
      <Dialog
        fullScreen
        open={navBotOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ flexDirection: 'row-reverse' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setNavBotOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper sx={{ p: 3 }}>
          <SingleQuestion />
        </Paper>
      </Dialog>
      <div
        role="presentation"
        onClick={() => setShowQuickLinks(false)}
        onKeyDown={() => setShowQuickLinks(false)}
      >
        <Drawer anchor="right" open={showQuickLinks}>
          <List>
            <ListItem>
              <IconButton
                onClick={() => {
                  setCalendarModalOpen(true);
                }}
              >
                <CalendarToday />
              </IconButton>
            </ListItem>
            <ListItem>
              <IconButton onClick={() => setNewCaseOpen(true)}>
                <LocalHospital />
              </IconButton>
            </ListItem>
            <ListItem>
              <IconButton onClick={() => setNavBotOpen(true)}>
                <SiProbot />
              </IconButton>
            </ListItem>
            <ListItem>
              <IconButton
                onClick={() => {
                  // setMeshTreeOpen(true);
                }}
              >
                <Grain />
              </IconButton>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
