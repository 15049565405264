import axios from 'axios';
import localStorageUtils from 'src/utils/localStorageUtils';
import { BASE_URL } from '../utils/appConstants';
const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: 40000
});

// // Add a request interceptor
instance.interceptors.request.use((config) => {
  const token = localStorageUtils.getToken();
  if (token && token.length > 0) {
    config.headers.token = token;
  }
  return config;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log('Error in axios response: ', error.message);
    return new Promise(() => {});
  }
);

export default instance;
