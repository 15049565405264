import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import localStorageUtils from "src/utils/localStorageUtils";
import { Navigate } from "react-router";
import {
  requestEncounterDetails,
  requestCases,
  requestBarriers,
} from "src/api";
import EncounterDetails from "src/components/encounter/EncounterDetails";
import { useSearchParams } from "react-router-dom";
import BarrierListToolbar from "src/components/barrier/BarrierListToolbar";
import BarrierCard from "src/components/barrier/BarrierCard";
import moment from "moment";

const AddEncounter = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const [details, setDetails] = useState({
    navigatorId: currentUser.id,
    startTime: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
    endTime: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
    status: 1,
  });
  // const [patientList, setPatientList] = useState([]);
  const [caseList, setCaseList] = useState([]);
  const [barrierList, setBarrierList] = useState([]);
  const [searchParams] = useSearchParams();
  const titleString =
    searchParams.get("flag") === "apt" ? "Appointment" : "Encounter";

  useEffect(() => {
    const loadCaseBarriers = async (caseId) => {
      if (currentUser.id) {
        const r = await requestBarriers(currentUser.id, "allx", caseId);
        setBarrierList(r.data);
        console.log(r.data);
      }
    };

    const loadEncounterDetails = async (encounterid) => {
      const r1 = await requestEncounterDetails(encounterid);
      setDetails(r1.data);
      loadCaseBarriers(r1.data.caseId);
    };
    // const loadActivePatients = async (navigatorId) => {
    //   const r2 = await requestPatients(navigatorId, '1');
    //   console.log(r2.data);
    //   setPatientList(r2.data);
    // };
    const loadActiveCases = async (navigatorId) => {
      const r3 = await requestCases(navigatorId, "1");
      console.log(r3.data);
      setCaseList(r3.data);
    };
    // loadActivePatients(currentUser.id);

    loadActiveCases(currentUser.id);
    const encounterid = searchParams.get("encounterid");
    if (encounterid && encounterid != null) {
      loadEncounterDetails(encounterid);
    }
  }, [searchParams, currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>{titleString + " Details | CAPTNS"} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <EncounterDetails
                details={details}
                cases={caseList}
                titlestring={titleString}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <BarrierListToolbar />
              <Box sx={{ pt: 3 }}>
                <Grid container spacing={3}>
                  {barrierList.map((barrier) => (
                    <Grid item key={barrier.id} lg={4} md={6} xs={12}>
                      <BarrierCard barrier={barrier} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddEncounter;
