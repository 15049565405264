import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useCreateOrUpdateUserMutation } from 'src/store/api/userApi';

const PatientDetails = forwardRef(
  ({ details, inStepper = false, setStepperData, ...props }, _ref) => {
    const [values, setValues] = useState();

    const [createOrUpdateUser, createOrUpdateUserResults] =
      useCreateOrUpdateUserMutation();

    useImperativeHandle(_ref, () => ({
      getPatientValues: () => values
    }));

    const [saveMessageDisplay, setSaveMessageDisplay] = useState('none');
    const [saveMessage, setSaveMessage] = useState();
    const [saveMessageCode, setSaveMessageCode] = useState();

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleBirthDateChange = (datePick) => {
      setValues({
        ...values,
        birthDate: moment(datePick._d).format('yyyy-MM-DD')
      });
    };

    const handleIntakeDateChange = (datePick) => {
      setValues({
        ...values,
        intakeDate: moment(datePick._d).format('yyyy-MM-DD')
      });
    };

    useEffect(() => {
      if (details) {
        setValues({
          ...details
        });
      }
    }, [details]);

    const handleUpdateDetails = async () => {
      setSaveMessageDisplay('none');
      const result = await createOrUpdateUser(values);
      const saveResult = result.data;
      setSaveMessageDisplay('');
      if (saveResult.code === 1) {
        setSaveMessageCode('success');
        setSaveMessage(saveResult.data);
      } else {
        setSaveMessageCode('error');
        setSaveMessage(saveResult.msg);
      }
    };

    return (
      <form autoComplete="off" noValidate {...props}>
        {!inStepper && (
          <p>
            <strong>PATIENT INFORMATION</strong>
          </p>
        )}

        <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
          <CardHeader title="BASIC INFORMATION" />
          <Divider />
          <CardContent sx={{ margin: 1 }}>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={values ? values.firstName : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={values ? values.lastName : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Preferred Name"
                  name="preferredName"
                  onChange={handleChange}
                  value={values ? values.preferredName : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-gender-native-simple">
                    Gender
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.gender : ''}
                    onChange={handleChange}
                    label="Gender"
                    inputProps={{
                      name: 'gender',
                      id: 'outlined-gender-native-simple'
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={1}>Male</option>
                    <option value={0}>Female</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  InputProps={{ readOnly: true }}
                  clearable
                  fullWidth
                  label="Birth Date"
                  name="birthDate"
                  value={values ? values.birthDate : new Date()}
                  onChange={(date) => handleBirthDateChange(date)}
                  format="MM/DD/yyyy"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-gender-native-simple">
                    Ethnic Group
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.ethnicGroup : ''}
                    onChange={handleChange}
                    label="Ethnic Group"
                    inputProps={{
                      name: 'ethnicGroup',
                      id: 'outlined-gender-native-simple'
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={0}>African American</option>
                    <option value={1}>American Indian or Alaska Native</option>
                    <option value={2}>Asian</option>
                    <option value={3}>Hispanic or Latino</option>
                    <option value={4}>
                      Native Hawaiian or Other Pacific Islander
                    </option>
                    <option value={5}>White</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
          <CardHeader title="CONTACT INFORMATION" />
          <Divider />
          <CardContent sx={{ margin: 1 }}>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  value={values ? values.email : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={handleChange}
                  value={values ? values.phone : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  onChange={handleChange}
                  value={values ? values.address : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  onChange={handleChange}
                  value={values ? values.city : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="State"
                  name="state"
                  onChange={handleChange}
                  value={values ? values.state : ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Zip Code"
                  name="zipCode"
                  onChange={handleChange}
                  value={values ? values.zipCode : ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  onChange={handleChange}
                  value={values ? values.country : ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
          <CardHeader title="NAVAGATION INFORMATION" />
          <Divider />
          <CardContent sx={{ margin: 1 }}>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Diagnosis"
                  name="diagnosis"
                  onChange={handleChange}
                  value={values ? values.diagnosis : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  InputProps={{ readOnly: true }}
                  clearable
                  fullWidth
                  label="Intake Date"
                  name="intakeDate"
                  value={values ? values.intakeDate : new Date()}
                  onChange={(date) => handleIntakeDateChange(date)}
                  format="MM/DD/yyyy"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-insurance-native-simple">
                    Insurance Type
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.insurance : ''}
                    onChange={handleChange}
                    label="Insurance Type"
                    inputProps={{
                      name: 'insurance',
                      id: 'outlined-insurance-native-simple'
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={0}>Commerical</option>
                    <option value={1}>Governmental </option>
                    <option value={2}>No Insurance</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Insurance Carrier"
                  name="insuranceNote"
                  onChange={handleChange}
                  value={values ? values.insuranceNote : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-status-native-simple">
                    Status
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.status : 1}
                    onChange={handleChange}
                    label="Status"
                    inputProps={{
                      name: 'status',
                      id: 'outlined-status-native-simple'
                    }}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  multiline
                  fullWidth
                  label="Other Notes"
                  name="notes"
                  onChange={handleChange}
                  value={values ? values.notes : ''}
                  variant="outlined"
                  minRows="1"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {!inStepper && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Alert
              severity={saveMessageCode}
              sx={{ display: saveMessageDisplay }}
            >
              {saveMessage}
            </Alert>
            <Button
              color="primary"
              variant="contained"
              disabled={createOrUpdateUserResults.isLoading}
              onClick={handleUpdateDetails}
            >
              Save Patient
            </Button>
          </Box>
        )}
      </form>
    );
  }
);

export default PatientDetails;
