import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import EncounterListToolbar from 'src/components/encounter/EncounterListToolbar';
import localStorageUtils from 'src/utils/localStorageUtils';
import EncounterCard from 'src/components/encounter/EncounterCard';
import { useGetEncountersByNavigatorQuery } from 'src/store/api/encounterApi';
import LoadingCircle from 'src/components/LoadingCirecle';

const EncounterList = () => {
  const currentUser = localStorageUtils.getCurrentUser();

  const { data, isFetching, error } = useGetEncountersByNavigatorQuery({
    navigatorId: currentUser.id,
    status: 'all'
  });

  const encounterList = data?.data;
  return (
    <>
      <Helmet>
        <title>Encounters | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <EncounterListToolbar />
          {isFetching ? (
            <LoadingCircle />
          ) : error ? (
            <div>Error loading encounters</div>
          ) : (
            <Box sx={{ pt: 3 }}>
              <Grid container spacing={5}>
                {encounterList.map((encounter) => (
                  <Grid item key={encounter.id} lg={12} md={12} xs={12}>
                    <EncounterCard
                      encounter={encounter}
                      sx={{ boxShadow: 12 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default EncounterList;
