import { Box, CircularProgress } from '@material-ui/core';

function LoadingCircle(params) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        m: 1,
        p: 1,
        height: '100px'
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingCircle;
