import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';
import {
  CASE_TYPE,
  ENCOUNTER_STATUS,
  ENCOUNTER_TYPE
} from 'src/utils/appConstants';
// import GetAppIcon from '@material-ui/icons/GetApp';

const EncounterCard = ({ hideFooter, encounter, ...rest }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: 3
    }}
    {...rest}
  >
    <CardContent>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: 3
        }}
      >
        <Avatar
          alt="Encounter"
          src={encounter.navigationCase.id}
          variant="square"
        />
      </Box> */}
      <Typography align="left" color="textPrimary">
        {'ID'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {encounter.id}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'Patient'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {encounter.user.preferredName || encounter.user.firstName}{' '}
        {encounter.user.diagnosis ? `(${encounter.user.diagnosis})` : ''}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'Case'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {CASE_TYPE[encounter.navigationCase.type]}{' '}
        {encounter.navigationCase.startDate} ({encounter.navigationCase.id})
      </Typography>
      <Typography align="left" color="textPrimary">
        {'Type'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {ENCOUNTER_TYPE[encounter.type]}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'State Time'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {encounter.startTime}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'End Time'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {encounter.endTime}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'Notes'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {encounter.notes}
      </Typography>
      <Typography align="left" color="textPrimary">
        {'Status'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {ENCOUNTER_STATUS[encounter.status]}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />

    {!hideFooter && (
      <>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <AccessTimeIcon color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                {encounter.startTime}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {/* <GetAppIcon color="action" /> */}
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                {'Status: '}
                {encounter.status}
                <Link to={'/app/add-encounter?encounterid=' + encounter.id}>
                  {' Details'}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    )}
  </Card>
);

EncounterCard.propTypes = {
  encounter: PropTypes.object.isRequired
};

export default EncounterCard;
