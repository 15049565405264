import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { requestBarrierDetails } from 'src/api';
import BarrierDetails from 'src/components/barrier/BarrierDetails';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useGetCasesByNavigatorQuery } from 'src/store/api/caseApi';

const AddBarrier = () => {
  const currentUser = localStorageUtils.getCurrentUser();

  const { data: navigatorCasesData } = useGetCasesByNavigatorQuery({
    navigatorId: currentUser.id,
    status: 'all'
  });

  const caseList = navigatorCasesData?.data;

  const [details, setDetails] = useState({
    navigatorId: currentUser.id,
    introduceDate: moment(new Date()).format('yyyy-MM-DD'),
    status: 2
  });
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const loadBarrierDetails = async (barrierid) => {
      const r1 = await requestBarrierDetails(barrierid);
      console.log('loadBarrierDetails: ', r1.data);
      setDetails(r1.data);
    };
    // const loadActivePatients = async (navigatorId) => {
    //   const r2 = await requestPatients(navigatorId, '1');
    //   console.log(r2.data);
    //   setPatientList(r2.data);
    // };
    // const loadActiveCases = async (navigatorId) => {
    //   const r3 = await requestCases(navigatorId, '1');
    //   console.log(r3.data);
    //   setCaseList(r3.data);
    // };
    // loadActivePatients(currentUser.id);
    // loadActiveCases(currentUser.id);
    const barrierid = searchParams.get('barrierid');
    if (barrierid && barrierid != null) {
      loadBarrierDetails(barrierid);
    }
  }, [searchParams, currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Barrier Details | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <BarrierDetails
                details={details}
                cases={caseList?.filter((theCase) => theCase.status === 1)}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddBarrier;
