import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';
import { BARRIER_TYPE } from 'src/utils/appConstants';
// import GetAppIcon from '@material-ui/icons/GetApp';

const BarrierCard = ({ inStepper = false, hideFooter, barrier, ...rest }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: 3
    }}
    {...rest}
  >
    <CardContent>
      {!inStepper && (
        <>
          <Typography align="left" color="textPrimary" gutterBottom>
            {'Barrier ID: '}
            {barrier?.id}
          </Typography>

          <Typography align="left" color="textPrimary" gutterBottom>
            {'Patient: '}
            {barrier?.user?.preferredName}
          </Typography>
          <Typography align="left" color="textPrimary" gutterBottom>
            {'Case ID: '}
            {barrier?.navigationCase?.id}
          </Typography>
        </>
      )}
      <Typography align="left" color="textPrimary" variant="body1">
        {'Type: '}
        {BARRIER_TYPE[barrier?.category]}
      </Typography>
      <Typography align="left" color="textPrimary" variant="body1">
        {'Name: '}
        {barrier?.name}
      </Typography>
      <Typography align="left" color="textPrimary" variant="body1">
        {'IntroduceDate Date: '}
        {barrier?.introduceDate}
      </Typography>
      <Typography align="left" color="textPrimary" variant="body1">
        {'Status: '}
        {barrier?.status}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />
    {!hideFooter && (
      <>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <AccessTimeIcon color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                {barrier?.firstEncounterDate}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {/* <GetAppIcon color="action" /> */}
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                {'Status: '}
                {barrier?.status}
                <Link to={'/app/add-barrier?barrierid=' + barrier?.id}>
                  {' Details'}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    )}
  </Card>
);

BarrierCard.propTypes = {
  barrier: PropTypes.object.isRequired
};

export default BarrierCard;
