import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { BARRIER_STATUS } from 'src/utils/appConstants';
import moment from 'moment/moment';

function BarriersCompare({ barrierList, defaultExpanded, currentDate }) {
  const snapDate = moment(currentDate);

  return (
    <Accordion defaultExpanded={defaultExpanded} style={{ width: '100%' }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="h1">
          Barriers status on ({currentDate})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ borderRight: 1, borderRightColor: 'purple' }}
          >
            <Typography variant="h6" component="h1">
              {BARRIER_STATUS[1]}
            </Typography>
            <Box>
              <Grid container spacing={1}>
                {
                  // status is 1 when snapDate
                  barrierList
                    ?.filter((b) => {
                      if (b.status === 1) {
                        // active
                        return snapDate.diff(moment(b.startDate), 'days') >= 0;
                      }
                      if (b.status === 0) {
                        return (
                          snapDate.diff(moment(b.startDate), 'days') >= 0 &&
                          snapDate.diff(moment(b.addressDate), 'days') < 0
                        );
                      }
                      return false;
                    })
                    .map((barrier) => (
                      <Grid item key={barrier.id}>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title={barrier.name}
                        >
                          <Chip
                            sx={{ textOverflow: 'ellipsis', maxWidth: '150px' }}
                            avatar={<Avatar>M</Avatar>}
                            color="secondary"
                            label={barrier.name}
                            onClick={() => {
                              console.log('');
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    ))
                }
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ borderRight: 1, borderRightColor: 'purple' }}
          >
            <Typography variant="h6" component="h1">
              {BARRIER_STATUS[0]}
            </Typography>
            <Box>
              <Grid container spacing={1}>
                {
                  // status is 0 when currentDate
                  barrierList
                    ?.filter((b) => {
                      if (b.status === 0) {
                        return (
                          snapDate.diff(moment(b.addressDate), 'days') >= 0
                        );
                      }
                      return false;
                    })
                    .map((barrier) => (
                      <Grid item key={barrier.id}>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title={barrier.name}
                        >
                          <Chip
                            sx={{ textOverflow: 'ellipsis', maxWidth: '150px' }}
                            avatar={<Avatar>M</Avatar>}
                            label={barrier.name}
                            clickable
                            color="primary"
                            onDelete={() => {
                              console.log('');
                            }}
                            deleteIcon={<DoneIcon />}
                          />
                        </Tooltip>
                      </Grid>
                    ))
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h6" component="h1">
              {BARRIER_STATUS[2]}
            </Typography>
            <Box>
              <Grid container spacing={1}>
                {
                  // status is 2 when currentDate
                  barrierList
                    ?.filter((b) => {
                      if (b.status === 2) {
                        return (
                          snapDate.diff(moment(b.introduceDate), 'days') >= 0
                        );
                      }
                      if (b.status === 1 || b.status === 0) {
                        return (
                          snapDate.diff(moment(b.introduceDate), 'days') >= 0 &&
                          snapDate.diff(moment(b.startDate), 'days') < 0
                        );
                      }
                      return false;
                    })
                    .map((barrier) => (
                      <Grid item key={barrier.id}>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title={barrier.name}
                        >
                          <Chip
                            sx={{ textOverflow: 'ellipsis', maxWidth: '150px' }}
                            label={barrier.name}
                            onClick={() => {
                              console.log('');
                            }}
                            clickable
                          />
                        </Tooltip>
                      </Grid>
                    ))
                }
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default BarriersCompare;
