import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';
// import GetAppIcon from '@material-ui/icons/GetApp';

const CaseCard = ({ navCase, ...rest }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: 3,
    }}
    {...rest}
  >
    <CardContent>
      <Typography align="left" color="textPrimary" gutterBottom variant="h5">
        {'Case ID: '}
        {navCase?.id}
      </Typography>
      <Typography align="left" color="textPrimary" gutterBottom variant="h5">
        {'Patient: '}
        {navCase?.user?.preferredName}
      </Typography>
      <Typography align="left" color="textPrimary" variant="body1">
        {'Notes: '}
        {navCase?.notes}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />
    <Divider />
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <AccessTimeIcon color="action" />
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 1 }}
            variant="body2"
          >
            {navCase?.startDate}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {/* <GetAppIcon color="action" /> */}
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 1 }}
            variant="body2"
          >
            {'Status: '}
            {navCase?.status}
            <Link to={'/app/view-case?caseid=' + navCase?.id}>{' View'}</Link>
            <Link to={'/app/add-case?caseid=' + navCase?.id}>{' Edit'}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

CaseCard.propTypes = {
  navCase: PropTypes.object.isRequired,
};

export default CaseCard;
