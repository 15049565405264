import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
  withStyles
} from '@material-ui/core';
import { useRef, useState } from 'react';
import { BARRIER_REF, BARRIER_TYPE } from 'src/utils/appConstants';
import BarrierDetails from '../barrier/BarrierDetails';
import moment from 'moment';

const LMAccordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

function AddBarrierTemplate({
  open,
  handleOpen,
  inStepper,
  setStepperBarriers,
  ...props
}) {
  const barrierDetailsRef = useRef();
  const [showBarrierRef, setShowBarrierRef] = useState(true);
  const [expanded, setExpanded] = useState('panel0');
  const [checkedBarriers, setCheckedBarriers] = useState([]);
  const handleAdd = () => {
    if (!showBarrierRef) {
      console.log(
        'checkedBarriers',
        barrierDetailsRef.current.getBarrierValues()
      );
      setStepperBarriers([barrierDetailsRef.current.getBarrierValues()]);
    } else {
      console.log('checkedBarriers', checkedBarriers);
      setStepperBarriers(checkedBarriers);
      setCheckedBarriers([]);
    }

    handleOpen(false);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle
        id="form-dialog-title"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        Add Barrier{' '}
        <Switch
          checked={showBarrierRef}
          onChange={() => setShowBarrierRef(!showBarrierRef)}
          color="primary"
        />{' '}
        <label>Import from refs</label>
      </DialogTitle>
      <DialogContent>
        {showBarrierRef ? (
          <>
            {BARRIER_TYPE.map((barrierType, barrierTypeIndex) => {
              return (
                <LMAccordion
                  square
                  expanded={expanded === `panel${barrierTypeIndex}`}
                  onChange={handleChange(`panel${barrierTypeIndex}`)}
                >
                  <AccordionSummary
                    aria-controls={`panel${barrierTypeIndex}d-content`}
                    id={`panel${barrierTypeIndex}d-header`}
                  >
                    <Typography>{barrierType}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {BARRIER_REF[barrierType].map((barrierItem) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="addBarrierItems"
                            color="primary"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckedBarriers([
                                  ...checkedBarriers,
                                  {
                                    introduceDate:
                                      moment().format('yyyy-MM-DD'),
                                    status: 2,
                                    category: barrierTypeIndex,
                                    name: barrierItem
                                  }
                                ]);
                              } else {
                                const filtered = checkedBarriers.filter(
                                  (item) => item.name !== barrierItem
                                );
                                setCheckedBarriers([...filtered]);
                              }
                            }}
                          />
                        }
                        label={barrierItem}
                      />
                    ))}
                  </AccordionDetails>
                </LMAccordion>
              );
            })}
          </>
        ) : (
          <BarrierDetails
            inStepper={inStepper}
            ref={barrierDetailsRef}
            details={{
              status: 2,
              introduceDate: moment().format('yyyy-MM-DD')
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleAdd}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AddBarrierTemplate;
