import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateCaseDetails } from 'src/api';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const CaseDetails = forwardRef(
  ({ inStepper = false, details, patients, ...props }, _ref) => {
    const [values, setValues] = useState();

    const [saveMessageDisplay, setSaveMessageDisplay] = useState('none');
    const [saveMessage, setSaveMessage] = useState();
    const [saveMessageCode, setSaveMessageCode] = useState();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useImperativeHandle(_ref, () => ({
      getCaseValues: () => values
    }));

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleStartDateChange = (datePick) => {
      setValues({
        ...values,
        startDate: moment(datePick._d).format('yyyy-MM-DD')
      });
    };

    useEffect(() => {
      if (details) {
        setValues({
          ...details
        });
      }
    }, [details]);

    const handleUpdateDetails = async () => {
      setSaveMessageDisplay('none');
      setSaveButtonDisabled(true);
      const saveResult = await updateCaseDetails(values);
      console.log('saveResult: ', saveResult);
      setSaveMessageDisplay('');
      setSaveButtonDisabled(false);
      if (saveResult.code === 1) {
        setSaveMessageCode('success');
        setSaveMessage(saveResult.data);
      } else {
        setSaveMessageCode('error');
        setSaveMessage(saveResult.msg);
      }
    };

    return (
      <form autoComplete="off" noValidate {...props}>
        {!inStepper && (
          <p>
            <strong>CASE INFORMATION</strong>
          </p>
        )}
        <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
          <CardContent>
            <Grid container spacing={3}>
              {!inStepper && (
                <Grid item md={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-userId-native-simple">
                      Patient
                    </InputLabel>
                    <Select
                      native
                      value={values ? values.userId : ''}
                      onChange={handleChange}
                      label="Patient"
                      inputProps={{
                        name: 'userId',
                        id: 'outlined-userId-native-simple'
                      }}
                    >
                      <option aria-label="None" value="" />
                      {patients?.map((patient) => (
                        <option value={patient.id}>
                          {patient.preferredName
                            ? patient.preferredName
                            : patient.lastName + ' ' + patient.firstName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-type-native-simple">
                    Type
                  </InputLabel>
                  <Select
                    native
                    value={values ? values.type : ''}
                    onChange={handleChange}
                    label="Type"
                    inputProps={{
                      name: 'type',
                      id: 'outlined-type-native-simple'
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={1}>Knowledge Navigation</option>
                    <option value={2}>Medical Care Navigation</option>
                    <option value={3}>Health Navigation</option>
                    <option value={4}>Financial Navigation</option>
                    <option value={5}>Social Support Navigation</option>
                    <option value={6}>Customized Navigation</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  InputProps={{ readOnly: true }}
                  clearable
                  fullWidth
                  label="Start Date"
                  name="startDate"
                  value={values ? values.startDate : new Date()}
                  onChange={(date) => handleStartDateChange(date)}
                  format="MM/DD/yyyy"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  onChange={handleChange}
                  value={values ? values.notes : ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  onChange={handleChange}
                  value={values ? values.location : ''}
                  variant="outlined"
                />
              </Grid>

              {!inStepper && (
                <Grid item md={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-status-native-simple">
                      Status
                    </InputLabel>
                    <Select
                      native
                      value={values ? values.status : 1}
                      onChange={handleChange}
                      label="Status"
                      inputProps={{
                        name: 'status',
                        id: 'outlined-status-native-simple'
                      }}
                    >
                      <option value={0}>Inactive</option>
                      <option value={1}>Active</option>
                      <option value={2}>Closed</option>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        {!inStepper && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Alert
              severity={saveMessageCode}
              sx={{ display: saveMessageDisplay }}
            >
              {saveMessage}
            </Alert>
            <Button
              color="primary"
              variant="contained"
              disabled={saveButtonDisabled}
              onClick={handleUpdateDetails}
            >
              Save Case
            </Button>
          </Box>
        )}
      </form>
    );
  }
);

export default CaseDetails;
