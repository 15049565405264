import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Folder';
//import { green } from '@material-ui/core/colors';
import { useEffect, useState } from 'react';

const TotalCases = (props) => {
  const [activeCount, setActiveCount] = useState(0);
  const { details } = props;
  useEffect(() => {
    if (details && details.length >= 0) {
      let r = 0;
      details.forEach(item => {
        // if (item.status === 1) {
        //   r++;
        // }
        r++;
      });
      setActiveCount(r);
    }
  }, [details]);

  return (
    <Card
      sx={{
        height: '100%',
        boxShadow: 12,
      }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'center' }}
        >
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: '#3A75C4',
                height: 56,
                width: 56
              }}
            >
              <TimerIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL CASES
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              sx={{ textAlign: 'center' }}
            >
              {activeCount}
            </Typography>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
  )
};

export default TotalCases;
