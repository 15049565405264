import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@material-ui/core";
import localStorageUtils from "src/utils/localStorageUtils";
import { Navigate } from "react-router";
import { requestUserDetails } from "src/api";
import PatientDetails from "src/components/patient/PatientDetails";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const AddPatient = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const [details, setDetails] = useState({
    navigatorId: currentUser.id,
    birthDate: moment(new Date()).format("yyyy-MM-DD"),
    intakeDate: moment(new Date()).format("yyyy-MM-DD"),
    status: 1,
  });
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const loadUserDetails = async (userId) => {
      const result = await requestUserDetails(userId);
      setDetails(result.data);
    };
    const userId = searchParams.get("id");
    if (userId && userId != null) {
      loadUserDetails(userId);
    }
  }, [searchParams]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Patient Details | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <PatientDetails details={details} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AddPatient;
