import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import localStorageUtils from 'src/utils/localStorageUtils';
import PatientCard from 'src/components/patient/PatientCard';
import PatientListToolbar from 'src/components/patient/PatientListToolbar';
import { useGetUsersByNavigatorQuery } from 'src/store/api/userApi';
import LoadingCircle from 'src/components/LoadingCirecle';
import { useEffect, useState } from 'react';

const PatientList = () => {
  const currentUser = localStorageUtils.getCurrentUser();

  const { data, isFetching, error } = useGetUsersByNavigatorQuery({
    navigatorId: currentUser.id,
    status: 'all'
  });

  const [queryTerm, setQueryTerm] = useState('');
  const [userStatus, setUserStatus] = useState(1);
  const [filteredData, setFilteredData] = useState(data?.data);

  const changeQueryTerm = (queryTerm) => {
    setQueryTerm(queryTerm);
  };

  const changeUserStatus = (userStatus) => {
    setUserStatus(userStatus);
  };

  useEffect(() => {
    const termOkayFor = (theProp) => {
      return theProp
        ?.toLocaleLowerCase()
        .includes(queryTerm.toLocaleLowerCase());
    };

    if (data) {
      const filtered = data?.data.filter((user) => {
        const statusOkay =
          userStatus === 'all' ||
          parseInt(user.status) === parseInt(userStatus);
        const termOkay =
          termOkayFor(user.preferredName) ||
          termOkayFor(user.firstName) ||
          termOkayFor(user.lastName) ||
          termOkayFor(user.email) ||
          termOkayFor(user.diagnosis) ||
          termOkayFor(user.preferredName);
        return statusOkay && termOkay;
      });
      setFilteredData([...filtered]);
    }
  }, [queryTerm, userStatus, data]);

  return (
    <>
      <Helmet>
        <title>Patients | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <PatientListToolbar
            queryTerm={queryTerm}
            userStatus={userStatus}
            changeQueryTerm={changeQueryTerm}
            changeUserStatus={changeUserStatus}
          />
          {isFetching ? (
            <LoadingCircle />
          ) : error ? (
            <div>Loading patients error...</div>
          ) : (
            <Box sx={{ pt: 3}}>
              <Grid container spacing={3}>
                {filteredData?.map((patient) => (
                  <Grid item key={patient.id} lg={12} md={12} xs={12} >
                    <PatientCard patient={patient} sx={{boxShadow: 12}} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PatientList;
