import { Button, Input } from '@material-ui/core';
import axios from 'axios';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import { drawMeshNodes } from './Charts';

function MeshTree(params) {
  const ref = useRef();

  const [termInput, setTermInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('pancreatic neoplasms');
  const [clickTerm, setClickTerm] = useState('');
  const [chartData, setChartData] = useState({ nodes: [], links: [] });

  const onClick = (d) => {
    if (d && d.name) {
      setClickTerm(d.name);
    }
  };

  useEffect(() => {
    let dkw = searchTerm;
    if (clickTerm && clickTerm.length > 0) {
      dkw = clickTerm;
    }
    axios
      .get('https://www.linksciences.com/getNeighbours.do', {
        params: { dkw }
      })
      .then((res) => {
        if (clickTerm && clickTerm.length > 0) {
          // apend data
          setChartData((current) => {
            const filteredNodes = res.data.nodes.filter((node) => {
              let flag = true;
              current.nodes.forEach((preNode) => {
                if (node.id === preNode.id) flag = false;
              });
              return flag;
            });

            const currentGroup = current.nodes[current.nodes.length - 1]?.group;

            filteredNodes.forEach((node) => {
              node.group = currentGroup ? currentGroup + 1 : 1;
            });

            const filteredLinks = res.data.links.filter((link) => {
              let flag = true;
              current.links.forEach((preLink) => {
                if (
                  preLink.sourceId === link.sourceId &&
                  preLink.targetId === link.targetId
                )
                  flag = false;
              });
              return flag;
            });
            return {
              nodes: [...current.nodes, ...filteredNodes],
              links: [...current.links, ...filteredLinks]
            };
          });
        } else {
          res.data.nodes.forEach((node) => {
            node.group = 0;
          });
          setChartData({ nodes: res.data.nodes, links: res.data.links });
        }
      });
  }, [searchTerm, clickTerm]);

  useEffect(() => {
    console.log('useEffect drawMeshNodes');
    d3.select(ref.current).selectAll('*').remove();
    drawMeshNodes(
      d3.select(ref.current),
      chartData,
      window.innerWidth,
      window.innerHeight,
      onClick
    );
  }, [chartData]);

  return (
    <>
      <div>
        <Input
          aria-label="Mesh Term"
          value={termInput}
          onChange={(e) => setTermInput(e.target.value)}
        ></Input>
        <Button
          onClick={() => {
            setClickTerm('');
            setSearchTerm(termInput);
          }}
        >
          Submit
        </Button>
      </div>
      <svg ref={ref}></svg>
    </>
  );
}

export default MeshTree;
