import { Helmet } from 'react-helmet';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  // Card,
  // CardContent,
  Container,
  Grid,
  Paper,
  styled,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyUpcomingEncounters from 'src/components/dashboard/MyUpcomingEncounters';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';
import { requestCases, requestEncounters, requestPatients } from 'src/api';
import { Link } from 'react-router-dom';
import TotalPatients from 'src/components/dashboard/TotalPatients';
import TotalEncoutners from 'src/components/dashboard/TotalEncoutners';
import TotalCases from 'src/components/dashboard/TotalCases';
import EncounterCalendar from 'src/components/encounter/EncounterCalendar';

const Dashboard = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const [encounterList, setEncounterList] = useState();
  const [patientList, setPatientList] = useState();
  const [caseList, setCaseList] = useState([]);
  const [reloadDetails, setReloadDetails] = useState(0);

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.text.secondary
  }));

  useEffect(() => {
    const initEncounters = async () => {
      if (currentUser.id) {
        const r = await requestEncounters(currentUser.id, 'all');
        setEncounterList(r.data);
      }
    };
    initEncounters();
  }, [reloadDetails, currentUser.id]);

  useEffect(() => {
    const initCases = async () => {
      if (currentUser.id) {
        const r = await requestCases(currentUser.id, 'all');
        setCaseList(r.data);
      }
    };
    initCases();
  }, [reloadDetails, currentUser.id]);

  useEffect(() => {
    const initPatients = async () => {
      if (currentUser.id) {
        const r = await requestPatients(currentUser.id, 'all');
        setPatientList(r.data);
      }
    };
    initPatients();
  }, [reloadDetails, currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 5
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={6}>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalPatients details={patientList} />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalCases details={caseList} />
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={12}>
              <TotalEncoutners details={encounterList} />
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
              <Paper elevation="12" sx={{ p: 3 }}>
                <EncounterCalendar
                  encounterList={encounterList}
                  caseList={caseList}
                />
              </Paper>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
              <Paper elevation="12">
                <StyledAccordion defaultExpanded="true">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Quick Links</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={5}>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Link to="/app/add-encounter">
                          <Button variant="contained" fullWidth>
                            Log Encounter
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Link to="/app/add-encounter?flag=apt">
                          <Button variant="contained" fullWidth>
                            Create Appointment
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Link to="/app/add-case">
                          <Button variant="contained" fullWidth>
                            Create Case
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Link to="/app/add-patient">
                          <Button variant="contained" fullWidth>
                            Enroll Patient
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              </Paper>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ overflowX: 'scroll' }}
            >
              <MyUpcomingEncounters
                encounterList={encounterList}
                reloadEncounterList={setReloadDetails}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
