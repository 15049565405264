import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  // Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Link } from 'react-router-dom';
// import GetAppIcon from '@material-ui/icons/GetApp';

const PatientCard = ({ patient, ...rest }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: 3
    }}
    {...rest}
  >
    <CardContent>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: 3
        }}
      >
        <Avatar
          alt="Encounter"
          src={encounter.navigationCase.id}
          variant="square"
        />
      </Box> */}
      <Typography
        align="left"
        color="textPrimary"
        gutterBottom
        variant="h5"
      >
        {'Patient ID: '}
        {patient.id}
      </Typography>
      <Typography
        align="left"
        color="textPrimary"
        gutterBottom
        variant="h5"
      >
        {'Name: '}
        {patient.preferredName}
      </Typography>
      <Typography
        align="left"
        color="textPrimary"
        variant="body1"
      >
        {'Email: '}
        {patient.email}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />
    <Divider />
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <AccessTimeIcon color="action" />
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 1 }}
            variant="body2"
          >
            {patient.city}
            {' '}
            {patient.state}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {/* <GetAppIcon color="action" /> */}
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 1 }}
            variant="body2"
          >
            {'Status: '}
            {patient.status}
            <Link to={'/app/view-patient?patientid=' + patient.id} >
              {' View'}
            </Link>
            <Link to={'/app/add-patient?id=' + patient.id} >
              {' Edit'}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

PatientCard.propTypes = {
  patient: PropTypes.object.isRequired
};

export default PatientCard;
