import { Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { useState } from 'react';
import { CASE_STATUS } from 'src/utils/appConstants';
import BarrierCard from '../barrier/BarrierCard';
import EncounterCard from '../encounter/EncounterCard';
import TabPanel from '../TabPanel';
import CaseTimeline from './CaseTimeline';

function CaseTabs({ caseInfo, encounterList, barrierList, currentCaseId }) {
  const [value, setValue] = useState(0);
  return (
    <Paper>
      <Tabs
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
        aria-label="case tabs"
      >
        <Tab label="Case Info" />
        <Tab label="Encounters" />
        <Tab label="Barriers" />
        <Tab label="Timeline" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography gutterBottom>ID</Typography>
        <Typography variant="body2" color="textSecondary">
          {caseInfo.id}
        </Typography>
        <Typography gutterBottom>Start Date</Typography>
        <Typography variant="body2" color="textSecondary">
          {caseInfo.startDate}
        </Typography>
        <Typography gutterBottom>Location</Typography>
        <Typography variant="body2" color="textSecondary">
          {caseInfo.location}
        </Typography>
        <Typography gutterBottom>Notes</Typography>
        <Typography variant="body2" color="textSecondary">
          {caseInfo.notes}
        </Typography>
        <Typography gutterBottom>Status</Typography>
        <Typography variant="body2" color="textSecondary">
          {CASE_STATUS[caseInfo.status]}
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {encounterList?.map((item) => {
          return (
            <EncounterCard
              hideFooter={true}
              encounter={item}
              key={item.id}
              sx={{ mb: 3 }}
            />
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {barrierList?.map((item) => {
          return (
            <BarrierCard
              hideFooter={true}
              barrier={item}
              key={item.id}
              sx={{ mb: 3 }}
            />
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={3} nopadding>
        <CaseTimeline
          barrierList={barrierList}
          encounterList={encounterList}
          caseInfo={caseInfo}
        />
      </TabPanel>
    </Paper>
  );
}

export default CaseTabs;
