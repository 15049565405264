import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
  apiKey: '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'
});
const openai = new OpenAIApi(configuration);
const generateAnswer = async (questionInput) => {
  console.log('questionInput : ', questionInput);
  const result = {};

  console.log('qconfiguration.apiKey : ', configuration.apiKey);
  if (!configuration.apiKey) {
    result.error.message = 'OpenAI API key not configured';
    return result;
  }

  const question = questionInput || '';

  if (question.trim().length === 0) {
    result.error.message = 'Please enter a valid question';
    return result;
  }

  try {
    console.log('try prompt : ', question);
    const completion = await openai.createCompletion({
      model: 'text-davinci-003',
      prompt: generatePrompt(question),
      max_tokens: 1000,
      temperature: 0.6
    });
    console.log('try prompt end: ', completion);
    result.data = { result: completion.data.choices[0].text };
  } catch (error) {
    // Consider adjusting the error handling logic for your use case
    if (error.response) {
      console.error(error.response.status, error.response.data);
      // res.status(error.response.status).json(error.response.data);
      result.error.message = error.response.data;
    } else {
      console.error(`Error with OpenAI API request: ${error.message}`);
      result.error.message = 'An error occurred during your request.';
    }
  }
  return result;
};

function generatePrompt(question) {
  const _prompt = question[0].toUpperCase() + question.slice(1).toLowerCase();
  // add something related with patient navigation We are talking about patient navigation. I have a question, it is:
  return `${_prompt}`;
}

export { generateAnswer };
