import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import CaseListToolbar from 'src/components/case/CaseListToolbar';
import localStorageUtils from 'src/utils/localStorageUtils';
import CaseCard from 'src/components/case/CaseCard';
import { useGetCasesByNavigatorQuery } from 'src/store/api/caseApi';
import LoadingCircle from 'src/components/LoadingCirecle';

const CaseList = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const { data, isFetching, error } = useGetCasesByNavigatorQuery({
    navigatorId: currentUser.id,
    status: 'all'
  });
  const caseList = data?.data;
  console.log('caseListcaseList', caseList);
  return (
    <>
      <Helmet>
        <title>Cases | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CaseListToolbar />
          {isFetching ? (
            <LoadingCircle />
          ) : error ? (
            <div>Error loading cases</div>
          ) : (
            <Box sx={{ pt: 3 }}>
              <Grid container spacing={5}>
                {caseList?.map((navCase) => (
                  <Grid item key={navCase.id} lg={12} md={12} xs={12}>
                    <CaseCard navCase={navCase} sx={{ boxShadow: 12 }} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default CaseList;
