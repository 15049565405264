import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
// import { updateRunnerProfile, uploadImg } from 'src/api';
import { IMGAE_REQUEST_PREFIX } from 'src/utils/appConstants';

const AccountProfile = (props) => {
  const { profile } = props;
  const [previewImage, setPreviewImage] = useState('');

  // const [saveMessageDisplay, setSaveMessageDisplay] = useState('none');
  // const [saveMessage, setSaveMessage] = useState();
  // const [saveMessageCode, setSaveMessageCode] = useState();

  // const handleAvatarChange = async (event) => {
  //   // upload the avatar img
  //   const r = await uploadImg(event.target.files[0]);
  //   // update profile avatar information if uploaded.
  //   if (r.code === 1) {
  //     const avatarFileName = r.data;
  //     const r1 = await updateRunnerProfile({
  //       id: profile.id,
  //       avatar: avatarFileName
  //     });
  //     // set the image
  //     if (r1.code === 1) {
  //       setPreviewImage(IMGAE_REQUEST_PREFIX + avatarFileName);
  //       setSaveMessageDisplay('');
  //       setSaveMessageCode('success');
  //       setSaveMessage('Avatar updated.');
  //       setReloadProfile((pre) => pre + 1);
  //     } else {
  //       setSaveMessage(r1.code);
  //       setSaveMessageCode('error');
  //       setSaveMessage(r1.msg);
  //     }
  //   } else {
  //     setSaveMessage(r.code);
  //     setSaveMessageCode('error');
  //     setSaveMessage(r.msg);
  //   }
  // };

  useEffect(() => {
    if (profile) {
      setPreviewImage(IMGAE_REQUEST_PREFIX + profile.avatar);
    }
  }, [profile]);

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={previewImage}
            sx={{
              height: 90,
              width: 90
            }}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {profile ? profile.firstName : ''}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {profile ? `${profile.city} ${profile.country}` : ''}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountProfile;
