import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  // Link,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { requestLogin } from 'src/api';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Google } from '@material-ui/icons';
import { BASE_URL } from 'src/utils/appConstants';

export const OAUTH2_REDIRECT_URI = `${window.location.origin}/oauth2/redirect`;

const Login = () => {
  const navigate = useNavigate();
  const [displayValue, setDisplayValue] = useState('none');
  // errorMessage
  const [errorMessage, setErrorMessage] = useState();
  return (
    <>
      <Helmet>
        <title>Login | CAPTNS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values) => {
              const result = await requestLogin(values.email, values.password);
              console.log('result', result);
              if (result.code === 1) {
                setDisplayValue('none');
                localStorageUtils.setCurrentUser(
                  result.data.currentUser,
                  result.data.token
                );

                navigate('/app/dashboard', { replace: true });
              } else {
                setDisplayValue('');
                setErrorMessage(result.msg);
                console.log('Invalid username or password.');
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box> */}

                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login with email
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Alert severity="error" sx={{ display: displayValue }}>
                  {errorMessage}
                </Alert>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
                <Box
                  sx={{
                    pb: 1
                  }}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    - or -
                  </Typography>
                </Box>

                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={12}>
                    <Button
                      color="primary"
                      fullWidth
                      startIcon={<Facebook />}
                      onClick={() => {
                        console.log('Login with Facebook');
                      }}
                      size="large"
                      variant="contained"
                    >
                      Login with Facebook
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      startIcon={<Google />}
                      size="large"
                      href={`${BASE_URL}oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}`}
                    >
                      Login with Google
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
