import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Select,
  FormControl,
  InputLabel,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Link } from 'react-router-dom';

const PatientListToolbar = ({
  queryTerm,
  userStatus,
  changeQueryTerm,
  changeUserStatus,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Link to="/app/add-patient">
          <Button color="primary" variant="contained">
            Add Patient
          </Button>
        </Link>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card sx={{boxShadow: 12}}>
          <CardContent>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search patient(Name, diagnosis, email...)"
                  variant="outlined"
                  onChange={(e) => changeQueryTerm(e.target.value)}
                  value={queryTerm}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-patient-status">
                    Patient Status
                  </InputLabel>
                  <Select
                    native
                    value={userStatus}
                    label="Patient Status"
                    onChange={(e) => changeUserStatus(e.target.value)}
                  >
                    <option value={'all'}>All</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PatientListToolbar;
