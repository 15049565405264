import ajax from './ajax';

export const requestTopHospitals = (dkw) =>
  ajax.get('getTopHospitals.do', { params: { dkw } });

export const requestLogin = (email, password) =>
  ajax.post('/navigator/securityLogin', { email, password });

export const requestProfile = (id) => ajax.get('/navigator/' + id);

export const requestUserDetails = (id) => ajax.get('/user/' + id);

export const updateUserDetails = (details) => ajax.put('/user', details);

export const requestCaseDetails = (id) => ajax.get('/case/' + id);

export const updateCaseDetails = (details) => ajax.put('/case', details);

export const requestEncounterDetails = (id) => ajax.get('/encounter/' + id);

export const updateEncounterDetails = (details) =>
  ajax.put('/encounter', details);

export const requestBarrierDetails = (id) => ajax.get('/barrier/' + id);

export const updateBarrierDetails = (details) => ajax.put('/barrier', details);

export const requestPatients = (navigatorId, status, userQuery) =>
  ajax.get('/user/navigator/' + navigatorId + '/list', {
    params: { status, userQuery },
  });

export const requestCases = (navigatorId, status) =>
  ajax.get('/case/navigator/' + navigatorId + '/list', { params: { status } });

export const requestPatientCases = (patientId, navigatorId, status) =>
  ajax.get(`/case/navigator/${navigatorId}/patient/${patientId}/list`, {
    params: { status },
  });

export const requestEncounters = (navigatorId, status, patientId, caseId) =>
  ajax.get('/encounter/navigator/' + navigatorId + '/list', {
    params: { status, patientId, caseId },
  });

export const requestBarriers = (navigatorId, status, caseId) =>
  ajax.get('/barrier/navigator/' + navigatorId + '/list', {
    params: { status, caseId },
  });

export const updateNavigator = (navigator) => ajax.put('/navigator', navigator);

export const updateNavigatorPassword = (passwords) =>
  ajax.post('/navigator/updatepass', passwords);

export const requestRunningDetails = (runnerId) =>
  ajax.get('/running/detail/all/' + runnerId);

export const updateRunnerProfile = (runner) => ajax.put('/runner', runner);

export const updateRunnerPassword = (passwords) =>
  ajax.post('/runner/updatepass', passwords);

export const createRunningDetails = (runningDetails) =>
  ajax.post('/running/detail', runningDetails);

export const updateRunningDetails = (runningDetails) =>
  ajax.put('/running/detail', runningDetails);

export const deleteRunningDetails = (id) =>
  ajax.delete('/running/detail', { params: { id } });

export const downloadImg = (name) =>
  ajax.get('/common/download', { params: { name } });

export const uploadImg = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return ajax.post('/common/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
