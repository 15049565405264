import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL } from 'src/utils/appConstants';
import localStorageUtils from 'src/utils/localStorageUtils';

export const barrierApi = createApi({
  reducerPath: 'barriers',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('token', localStorageUtils.getToken());
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getBarriersByNavigator: builder.query({
      query: ({ navigatorId, status }) => ({
        method: 'GET',
        url: `/barrier/navigator/${navigatorId}/list`,
        params: { status }
      })
    }),
    createOrUpdateBarrier: builder.mutation({
      query: (barrier) => {
        return {
          method: 'PUT',
          url: '/barrier',
          body: barrier
        };
      }
    })
  })
});

export const {
  useGetBarriersByNavigatorQuery,
  useCreateOrUpdateBarrierMutation
} = barrierApi;
