import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
// import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { createRef, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { updateEncounterDetails } from 'src/api';
import { requestEncounterDetails } from 'src/api';
import moment from 'moment';
import localStorageUtils from 'src/utils/localStorageUtils';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'; // webpack uses file-loader to handle font files

const EncounterCalendar = (props) => {
  const currentUser = localStorageUtils.getCurrentUser();
  const { encounterList, caseList } = props;
  const [weekendsVisible, setWeekendsVisible] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [initEvents, setInitEvents] = useState([]);
  const [currentDialogEcounter, setCurrentDialogEcounter] = useState();
  const [calendarApi, setCalendarApi] = useState();
  const [currentEvent, setCurrentEvent] = useState();

  console.log(currentEvents, calendarApi, setWeekendsVisible);

  const calendarRef = createRef();

  // encounterModalOpen
  const [encounterDialogOpen, setEncounterDialogOpen] = useState(false);
  const handleChangeCurrentEncounter = (event) => {
    setCurrentDialogEcounter({
      ...currentDialogEcounter,
      [event.target.name]: event.target.value
    });
  };

  const handleStartTimeChange = (datePick) => {
    setCurrentDialogEcounter({
      ...currentDialogEcounter,
      startTime: moment(datePick._d).format('yyyy-MM-DD HH:mm:ss')
    });
  };

  const handleEndTimeChange = (datePick) => {
    setCurrentDialogEcounter({
      ...currentDialogEcounter,
      endTime: moment(datePick._d).format('yyyy-MM-DD HH:mm:ss')
    });
  };

  useEffect(() => {
    if (encounterList) {
      const initEvents = [];
      encounterList.forEach((element) => {
        initEvents.push({
          id: element.id,
          title: element.user.preferredName,
          start: element.startTime.replace(' ', 'T'),
          end: element.endTime.replace(' ', 'T')
        });
      });
      setInitEvents([...initEvents]);
    }
  }, [encounterList]);

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  // const handleWeekendsToggle = () => {
  //   setWeekendsVisible({
  //     weekendsVisible: !weekendsVisible
  //   });
  // };

  const handleDateSelect = (selectInfo) => {
    let currentCalendarApi = selectInfo.view.calendar;
    currentCalendarApi.unselect(); // clear date selection
    setCalendarApi(currentCalendarApi);
    console.log('selectInfo.startStr', selectInfo.startStr);
    console.log('selectInfo.endStr', selectInfo.endStr);
    // new encounter
    setCurrentDialogEcounter({
      navigatorId: currentUser.id,
      startTime: selectInfo.startStr.substring(0, 19).replace('T', ' '),
      endTime: selectInfo.endStr.substring(0, 19).replace('T', ' '),
      status: 1
    });
    setEncounterDialogOpen(true);
  };

  const handleCloseEncounterDialog = () => setEncounterDialogOpen(false);

  const handleSaveCurrentEncounter = async () => {
    console.log('handleSaveCurrentEncounter', currentDialogEcounter);
    if (currentDialogEcounter.caseId) {
      if (currentDialogEcounter.id) {
        // update
        console.log('update', currentDialogEcounter);
        const saveResult = await updateEncounterDetails(currentDialogEcounter);
        calendarRef.current.getApi().addEvent({
          id: saveResult.map.encounter.id,
          title: saveResult.map.encounter.user.preferredName,
          start: saveResult.map.encounter.startTime.replace(' ', 'T'),
          end: saveResult.map.encounter.endTime.replace(' ', 'T')
        });
        currentEvent.remove();
      } else {
        // new
        console.log('new', currentDialogEcounter);
        //console.log("calendarApi", calendarApi);
        const saveResult = await updateEncounterDetails(currentDialogEcounter);
        console.log('saveResult: ', saveResult);
        console.log('saveResult: ', saveResult.map.encounter.id);
        calendarRef.current.getApi().addEvent({
          id: saveResult.map.encounter.id,
          title: saveResult.map.encounter.user.preferredName,
          start: saveResult.map.encounter.startTime.replace(' ', 'T'),
          end: saveResult.map.encounter.endTime.replace(' ', 'T')
        });
      }
    } else {
      console.log('no case id');
      return;
    }
    setEncounterDialogOpen(false);
  };

  const handleEventClick = async (clickInfo) => {
    console.log('clickInfo.event.id', clickInfo.event.id);
    setCurrentEvent(clickInfo.event);
    let result = await requestEncounterDetails(clickInfo.event.id);
    // let theEncounter = encounterList.find(
    //   (element) => clickInfo.event.id === element.id
    // );
    console.log(result.data);
    setCurrentDialogEcounter(result.data);
    setEncounterDialogOpen(true);
  };

  const handleEvents = (events) => {
    console.log('handleEvents ', events);
    setCurrentEvents([...events]);
  };

  return (
    <>
      {/* Encoutner details dialog */}
      <Dialog
        open={encounterDialogOpen}
        onClose={handleCloseEncounterDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Encounter</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-caseId-native-simple">
                  Case
                </InputLabel>
                <Select
                  native
                  value={
                    currentDialogEcounter ? currentDialogEcounter.caseId : ''
                  }
                  onChange={handleChangeCurrentEncounter}
                  label="Case"
                  inputProps={{
                    name: 'caseId',
                    id: 'outlined-caseId-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  {caseList.map((theCase) => (
                    <option value={theCase.id}>
                      {theCase.user.preferredName +
                        ' Case ID: ' +
                        theCase.id +
                        ' ' +
                        theCase.startDate}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <KeyboardDateTimePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                label="Start Time"
                name="startTime"
                format="MM/DD/yyyy HH:mm"
                value={
                  currentDialogEcounter
                    ? currentDialogEcounter.startTime
                    : new Date()
                }
                onChange={(startTime) => handleStartTimeChange(startTime)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <KeyboardDateTimePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                format="MM/DD/yyyy HH:mm"
                label="End Time"
                name="endTime"
                value={
                  currentDialogEcounter
                    ? currentDialogEcounter.endTime
                    : new Date()
                }
                onChange={(endTime) => handleEndTimeChange(endTime)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                onChange={handleChangeCurrentEncounter}
                value={currentDialogEcounter ? currentDialogEcounter.notes : ''}
                variant="outlined"
                // sx={{backgroundColor: '#1EB0BC'}}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-status-native-simple">
                  Status
                </InputLabel>
                <Select
                  native
                  onChange={handleChangeCurrentEncounter}
                  label="Status"
                  value={
                    currentDialogEcounter ? currentDialogEcounter.status : 1
                  }
                  inputProps={{
                    name: 'status',
                    id: 'outlined-status-native-simple'
                  }}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                  <option value={2}>Following</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEncounterDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveCurrentEncounter} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <FullCalendar
        themeSystem="bootstrap"
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          bootstrapPlugin
        ]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',        
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',                    
        }}
        initialView="timeGridWeek"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        events={initEvents} // alternatively, use the `events` setting to fetch from a feed
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
      />
    </>
  );
};

export default EncounterCalendar;
