import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import EncounterList from 'src/pages/EncoutnerList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Logout from './pages/Logout';
import AddEncounter from './pages/AddEncounter';
import PatientList from './pages/PatientList';
import AddPatient from './pages/AddPatient';
import AddCase from './pages/AddCase';
import CaseList from './pages/CaseList';
import AddBarrier from './pages/AddBarrier';
import BarrierList from './pages/BarrierList';
import ViewPatient from './pages/ViewPatient';
import OAuth2RedirectHandler from './components/oauth2/OAuth2RedirectHandler';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'add-patient', element: <AddPatient /> },
      { path: 'add-case', element: <AddCase /> },
      { path: 'add-encounter', element: <AddEncounter /> },
      { path: 'add-barrier', element: <AddBarrier /> },
      { path: 'view-patient', element: <ViewPatient /> },
      { path: 'cases', element: <CaseList /> },
      { path: 'patients', element: <PatientList /> },
      { path: 'encounters', element: <EncounterList /> },
      { path: 'barriers', element: <BarrierList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'oauth2',
    element: <MainLayout />,
    children: [{ path: 'redirect', element: <OAuth2RedirectHandler /> }]
  }
];

export default routes;
