import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useRef, useState } from 'react';
import BarrierCard from '../barrier/BarrierCard';
import CaseDetails from '../case/CaseDetails';
import PatientDetails from '../patient/PatientDetails';
import AddBarrierTemplate from './AddBarrierTemplate';
import moment from 'moment';
import { CASE_TYPE } from 'src/utils/appConstants';
import { useCreateOrUpdateUserMutation } from 'src/store/api/userApi';
import localStorageUtils from 'src/utils/localStorageUtils';
import { useCreateOrUpdateCaseMutation } from 'src/store/api/caseApi';
import { useCreateOrUpdateBarrierMutation } from 'src/store/api/barrierApi';

const steps = ['Patient Information', 'Case & Barriers', 'Review'];

function NewCase({ newCaseOpen, setNewCaseOpen }) {
  const currentUser = localStorageUtils.getCurrentUser();
  const patientRef = useRef();
  const caseRef = useRef();

  const initState = {
    patientDetails: {
      navigatorId: currentUser.id,
      birthDate: moment().format('yyyy-MM-DD'),
      intakeDate: moment().format('yyyy-MM-DD'),
      status: 1
    },
    caseDetails: {
      navigatorId: currentUser.id,
      startDate: moment().format('yyyy-MM-DD'),
      status: 1
    },
    barrierList: []
  };

  const [createOrUpdateUser, createOrUpdateUserResult] =
    useCreateOrUpdateUserMutation();

  const [createOrUpdateCase, createOrUpdateCaseResult] =
    useCreateOrUpdateCaseMutation();

  const [createOrUpdateBarrier, createOrUpdateBarrierResult] =
    useCreateOrUpdateBarrierMutation();

  const [activeStep, setActiveStep] = useState(0);
  const [addBarrierTemplateOpen, setAddBarrierTemplateOpen] = useState(false);
  const [allSaved, setAllSaved] = useState(false);
  const [patientIdSaved, setPatientIdSaved] = useState(-1);
  const [stepperData, setStepperData] = useState({ ...initState });

  const setStepperBarriers = (bValues) => {
    setStepperData({
      ...stepperData,
      barrierList: [...stepperData.barrierList, ...bValues]
    });
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setStepperData({
        ...stepperData,
        patientDetails: patientRef.current.getPatientValues()
      });
    }
    if (activeStep === 1) {
      setStepperData({
        ...stepperData,
        caseDetails: caseRef.current.getCaseValues()
      });
    }
    if (activeStep === 2) {
      // Save all of the data
      // step 1: save the patient info
      const patientToSave = stepperData.patientDetails;
      const savedPatient = await createOrUpdateUser(patientToSave);
      console.log('savedPatient', savedPatient);
      if (savedPatient && savedPatient.data.map.user.id) {
        // step 2: save the case info
        const nvCaseToSave = {
          ...stepperData.caseDetails,
          userId: savedPatient.data.map.user.id
        };
        const savedNvCase = await createOrUpdateCase(nvCaseToSave);
        console.log('savedNvCase', savedNvCase);
        if (savedNvCase && savedNvCase.data.map.nvCase.id) {
          // step 3: save the barriers
          const barriersToSave = [...stepperData.barrierList];
          for (const item of barriersToSave) {
            const savedBarrier = await createOrUpdateBarrier({
              ...item,
              navigatorId: currentUser.id,
              caseId: savedNvCase.data.map.nvCase.id
            });
            console.log('savedBarrier', savedBarrier);
          }
          setPatientIdSaved(savedPatient.data.map.user.id);
          setAllSaved(true);
        }
      }
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      setStepperData({
        ...stepperData,
        patientDetails: patientRef.current.getPatientValues()
      });
    }
    if (activeStep === 1) {
      setStepperData({
        ...stepperData,
        caseDetails: caseRef.current.getCaseValues()
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setStepperData({ ...initState });
    setAllSaved(false);
    setActiveStep(0);
  };

  return (
    <Dialog
      fullScreen
      open={newCaseOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ flexDirection: 'row-reverse' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setNewCaseOpen(false)}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ py: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Container sx={{ px: 3, pt: 3 }}>
          {activeStep > steps.length ? (
            // we do not come here anymore
            <div>
              <Typography>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <>
              {activeStep === 0 && (
                <PatientDetails
                  ref={patientRef}
                  details={stepperData.patientDetails}
                  inStepper={true}
                />
              )}
              {activeStep === 1 && (
                <>
                  <CaseDetails
                    ref={caseRef}
                    inStepper={true}
                    details={stepperData.caseDetails}
                  />

                  <AddBarrierTemplate
                    open={addBarrierTemplateOpen}
                    inStepper={true}
                    setStepperBarriers={setStepperBarriers}
                    handleOpen={setAddBarrierTemplateOpen}
                  />
                  <Box sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ boxShadow: 12 }}
                      onClick={() => {
                        setAddBarrierTemplateOpen(true);
                      }}
                      
                    >
                      Add Barrier
                    </Button>
                  </Box>
                  <Grid container spacing={3} py={3}>
                    {stepperData.barrierList?.map((barrier, index) => (
                      <Grid item key={index} lg={4} md={4} xs={12}>
                        <BarrierCard
                          hideFooter
                          inStepper
                          barrier={barrier}
                          sx={{ boxShadow: 12 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
                    <CardHeader title="Patient Information" />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display="flex">
                          <Typography variant="h5" gutterBottom marginRight={3}>
                            First Name
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {stepperData.patientDetails?.firstName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex">
                          <Typography variant="h5" gutterBottom marginRight={3}>
                            Last Name
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {stepperData.patientDetails?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
                    <CardHeader title="Case Information" />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display="flex">
                          <Typography variant="h5" gutterBottom marginRight={3}>
                            Start Date
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {stepperData.caseDetails?.startDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex">
                          <Typography variant="h5" gutterBottom marginRight={3}>
                            Type
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {CASE_TYPE[stepperData.caseDetails.type]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card sx={{ marginBottom: 5, boxShadow: 12 }}>
                    <CardHeader title="Barriers" />
                    <CardContent>
                      <Grid container spacing={3} pb={3}>
                        {stepperData.barrierList?.map((barrier, index) => (
                          <Grid item key={index} lg={4} md={4} xs={12}>
                            <BarrierCard
                              hideFooter
                              inStepper
                              barrier={barrier}                              
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              )}

              <Box sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
                {allSaved ? (
                  <>
                    {/* <Link to={'/app/view-patient?patientid=' + patientIdSaved}>
                      <Button color="primary" variant="contained">
                        Go to case
                      </Button>
                    </Link> */}
                    {/* <Button color="primary" onClick={handleReset}>
                      Create Another
                    </Button> */}
                    <Button
                      color="primary"
                      variant="outlined"
                      href={'/app/view-patient?patientid=' + patientIdSaved}
                    >
                      Go to case
                    </Button>
                  </>
                ) : (
                  <>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={
                        createOrUpdateUserResult.isLoading ||
                        createOrUpdateCaseResult.isLoading ||
                        createOrUpdateBarrierResult.isLoading
                      }
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </Container>
      </Box>
    </Dialog>
  );
}

export default NewCase;
