export const BASE_URL = 'http://navigation.linkmedicine.com/';
// export const BASE_URL = 'http://localhost:8080/';
export const IMGAE_REQUEST_PREFIX = BASE_URL + 'common/download?name=';
export const ACCESS_TOKEN = 'token';
export const CASE_TYPE = [
  'No',
  'Knowledge Navigation',
  'Medical Care Navigation',
  'Health Navigation',
  'Financial Navigation',
  'Social Support Navigation',
  'Customized Navigation'
];

export const USER_STATUS = ['Inactive', 'Active'];
export const CASE_STATUS = ['Inactive', 'Active', 'Closed'];
export const ENCOUNTER_TYPE = ['No', 'In person', 'Phone'];
export const ENCOUNTER_STATUS = ['Inactive', 'Active', 'Closed'];
export const BARRIER_STATUS = ['Completed', 'Active', 'To be addressed'];
export const BARRIER_TYPE = [
  'Care Coordination',
  'Logistical',
  'Health Promotion',
  'Work/School',
  'Communication/Education',
  'Insurance/Costs'
];
export const BARRIER_REF = {
  'Care Coordination': [
    'Access to Care/ Systems Challenges',
    'Clinical Trials',
    'Coordinating Appointments/ Tests/ Other Procedures',
    'Coordinating Referrals e.g. primary care/ second opinions etc.',
    'End of Life Care Needs',
    'Fertility Service Needs',
    'Genetic Counseling or Oncotyping Needs',
    'Home Health Concerns',
    'Integrative Therapy Needs e.g. massage/ reiki etc.',
    'Nutrition Assistance Needs',
    'Physical Comorbidities/ Disabilities',
    'Rehabilitation Needs',
    'Symptom Management',
    'Other Care Coordination Needs'
  ],
  Logistical: [
    'Clothing',
    'Death and/or Funeral Expenses',
    'Dependent Care i.e. Child or Elder Care',
    'Food Insecurity',
    'General Financial Issues',
    'Housing',
    'Immigration Challenges',
    'Transportation',
    'Utilities'
  ],
  'Health Promotion': [
    'Healthy Eating Needs',
    'Physical Activity/ Exercise Needs',
    'Sun Safety Needs',
    'Tobacco Cessation Needs',
    'Weight Management Needs'
  ],
  'Work/School': [
    'Ability to Attend Classes Through Treatment',
    'Ability to Work Through Treatment',
    'Discrimination or Stigma at Work or School',
    'Family Member Employment Barriers',
    'Financial Issues and/or Lost Wages',
    'Job Termination',
    'Underemployment',
    'Unemployment',
    'Work Reintegration/ Integration'
  ],
  'Communication/Education': [
    'Empowerment/ Dissatisfaction or Concerns with Care',
    'Information Needs/ Questions/ Understanding',
    'Limited English Proficiency',
    'Literacy/ Health Literacy'
  ],
  'Insurance/Costs': [
    'Deductible/ Copays',
    'Medical Equipment and/or Supply Costs',
    'Medical and/or Medication Related Financial Barriers',
    'Underinsured',
    'Uninsured'
  ]

  // 'Cultural/ Religious/ Spiritual': [
  //   'Beliefs Not Aligned with Treatment',
  //   'Mistrust of Medical System',
  //   'Perceived Cultural Religious or Spiritual Stigma or Discrimination',
  //   'Privacy Concerns',
  //   'Other Cultural Concerns'
  // ],

  // 'Psychosocial/ Distress/ Behavioral': [
  //   'Difficulty Coping With Screening',
  //   'Difficulty Coping With Diagnosis',
  //   'Difficulty Coping With Treatment',
  //   'Difficulty Coping With Progression of Cancer',
  //   'Difficulty Coping With Survivorship',
  //   'Difficulty Coping with Secondary Cancer or Recurrence',
  //   'Difficulty Coping With End of Life',
  //   'Family Member/ Loved One/ Caregiver Distress',
  //   'Financial Distress',
  //   'Lack of Social Support',
  //   'Safety/ Domestic Violence',
  //   'Sexual Assault/ Trauma',
  //   'Sexuality/ Body Image Distress',
  //   'Substance Use and Abuse'
  // ]
};
