import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import {
  TimelineItem,
  Timeline,
  TimelineSeparator,
  TimelineOppositeContent,
  TimelineDot,
  TimelineConnector,
  TimelineContent
} from '@material-ui/lab';
import AlarmIcon from '@material-ui/icons/Alarm';
import BarriersCompare from '../barrier/BarriersCompare';
import { useEffect, useState } from 'react';
import CaseSliderView from './CaseSliderView';
import moment from 'moment';

function CaseTimeline({ barrierList, encounterList, caseInfo }) {
  const [showSliderView, setShowSliderView] = useState(false);
  const [sliderStart, setSliderStart] = useState(caseInfo.startDate);
  const [sliderEnd, setSliderEnd] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    // make the end date is max of { currentDate, lastEncounter.startDate}
    const genertateEndDate = () => {
      let endDate = moment();
      if (
        encounterList &&
        encounterList.length > 0 &&
        moment(encounterList[encounterList.length - 1].startTime).diff(
          endDate,
          'days'
        ) >= 0
      ) {
        endDate = moment(encounterList[encounterList.length - 1].startTime);
      }
      return endDate;
    };
    setSliderStart(caseInfo.startDate);
    setSliderEnd(genertateEndDate().format('YYYY-MM-DD'));
  }, [caseInfo, encounterList]);

  const timeLineEncounterList = [
    {
      id: '0',
      startTime: caseInfo?.startDate,
      notes: 'Case starting'
    },
    ...encounterList
  ];

  const content = showSliderView ? (
    <CaseSliderView
      barrierList={barrierList}
      encounterList={encounterList}
      sliderStart={sliderStart}
      sliderEnd={sliderEnd}
    />
  ) : (
    <Timeline>
      {timeLineEncounterList.map((encounter, index) => (
        <TimelineItem key={encounter.id}>
          <TimelineOppositeContent style={{ display: 'none' }}>
            <Typography variant="body2" color="textPrimary">
              {new Date(encounter.startTime).toLocaleDateString()}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              color={
                new Date() > new Date(encounter.startTime) ? 'grey' : 'primary'
              }
            >
              <AlarmIcon />
            </TimelineDot>
            {index !== encounterList.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent style={{ flexGrow: 8 }}>
            {/* <Paper className={classes.paper}> */}
            <Typography variant="h5" component="h1">
              {new Date(encounter.startTime).toLocaleDateString()}
            </Typography>
            <Typography variant="h6" component="h1">
              Encounter: {encounter.id}
            </Typography>
            <Typography variant="h6" component="h1">
              Notes: {encounter.notes}
            </Typography>
            <BarriersCompare
              barrierList={barrierList}
              currentDate={encounter.startTime}
            />
            {/* </Paper> */}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );

  return (
    <>
      <Grid container justifyContent="flex-end" alignContent="center">
        <FormControlLabel
          control={
            <Switch
              checked={showSliderView}
              onChange={() => setShowSliderView(!showSliderView)}
              name="checkedB"
              color="primary"
            />
          }
          label="debug"
        />
      </Grid>
      {showSliderView && (
        <Grid container justifyContent="space-between" padding={3} spacing={3}>
          <Grid item>
            <TextField
              label="Start"
              variant="standard"
              type="date"
              value={sliderStart}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => setSliderStart(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="End"
              variant="standard"
              type="date"
              value={sliderEnd}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => setSliderEnd(e.target.value)}
            />
          </Grid>
        </Grid>
      )}
      {content}
    </>
  );
}

export default CaseTimeline;
