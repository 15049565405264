import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateEncounterDetails } from 'src/api';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

const EncounterDetails = (props) => {
  const { details, cases, titlestring } = props;
  const [values, setValues] = useState();

  const [saveMessageDisplay, setSaveMessageDisplay] = useState("none");
  const [saveMessage, setSaveMessage] = useState();
  const [saveMessageCode, setSaveMessageCode] = useState();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleStartTimeChange = (datePick) => {
    setValues({
      ...values,
      startTime: moment(datePick._d).format("yyyy-MM-DD HH:mm:ss")
    });
  };

  const handleEndTimeChange = (datePick) => {
    setValues({
      ...values,
      endTime: moment(datePick._d).format("yyyy-MM-DD HH:mm:ss")
    });
  };

  useEffect(() => {
    if (details) {
      setValues({
        ...details
      });
    }
  }, [details]);

  const handleUpdateDetails = async () => {
    setSaveMessageDisplay("none");
    setSaveButtonDisabled(true);
    const saveResult = await updateEncounterDetails(values);
    console.log("saveResult: ", saveResult);
    setSaveMessageDisplay("");
    setSaveButtonDisabled(false);
    if (saveResult.code === 1) {
      setSaveMessageCode("success");
      setSaveMessage(saveResult.data);
    } else {
      setSaveMessageCode("error");
      setSaveMessage(saveResult.msg);
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          title={titlestring + ' Details'}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-userId-native-simple">Patient</InputLabel>
                <Select
                  native
                  value={values ? values.userId : ''}
                  onChange={handleChange}
                  label="Patient"
                  inputProps={{
                    name: 'userId',
                    id: 'outlined-userId-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {patients.map((patient) => (
                    <option value={patient.id}>{patient.preferredName ? patient.preferredName : patient.lastName + ' ' + patient.firstName}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-caseId-native-simple">Case</InputLabel>
                <Select
                  native
                  value={values ? values.caseId : ''}
                  onChange={handleChange}
                  label="Case"
                  inputProps={{
                    name: 'caseId',
                    id: 'outlined-caseId-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {cases.map((theCase) => (
                    <option value={theCase.id}>{theCase.user.preferredName + ' Case ID: ' + theCase.id + ' ' + theCase.startDate}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-type-native-simple">Type</InputLabel>
                <Select
                  native
                  value={values ? values.type : ''}
                  onChange={handleChange}
                  label="Type"
                  inputProps={{
                    name: 'type',
                    id: 'outlined-type-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={1}>In person</option>
                  <option value={2}>Phone</option>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <KeyboardDateTimePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                label="Start Time"
                name="startTime"
                value={values ? values.startTime : new Date()}
                onChange={startTime => handleStartTimeChange(startTime)}
                format="MM/DD/yyyy HH:mm"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <KeyboardDateTimePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                label="End Time"
                name="endTime"
                value={values ? values.endTime : new Date()}
                onChange={endTime => handleEndTimeChange(endTime)}
                format="MM/DD/yyyy HH:mm"
                variant="outlined"
              />
            </Grid>

            {/* 
            <Grid
              item
              md={6}
              xs={12}
            >
              <KeyboardDatePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                label="Close Date"
                name="closeDate"
                value={values ? values.closeDate : new Date()}
                onChange={date => handleCloseDateChange(date)}
                format="MM/DD/yyyy"
                variant="outlined"
              />
            </Grid> */}


            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                onChange={handleChange}
                value={values ? values.notes : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-status-native-simple">Status</InputLabel>
                <Select
                  native
                  value={values ? values.status : 1}
                  onChange={handleChange}
                  label="Status"
                  inputProps={{
                    name: 'status',
                    id: 'outlined-status-native-simple',
                  }}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                  <option value={2}>Following</option>
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Alert severity={saveMessageCode} sx={{ display: saveMessageDisplay }}>
            {saveMessage}
          </Alert>
          <Button
            color="primary"
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={handleUpdateDetails}
          >
            {'Save ' + titlestring}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default EncounterDetails;
