import { Button, Box, Grid, TextField } from '@material-ui/core';
import { useState } from 'react';
import { generateAnswer } from '../../api/openai_generate';

function SingleQuestion(props) {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('waiting...');
  const handleQuestion = async () => {
    const result = await generateAnswer(question);
    console.log('handleQuestion result ', result);
    if (result.error) {
      setAnswer(result.error.message);
    } else {
      setAnswer(result.data.result);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <TextField
            fullWidth
            label="Any Question?"
            name="question"
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            value={question}
            variant="outlined"
          />
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleQuestion}
          >
            Submit
          </Button>
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          {answer}
        </Box>
      </Grid>
    </Grid>
  );
}

export default SingleQuestion;
